import { Link } from 'react-router-dom'
import Layout from '../layout/layout'

export default function Home() {
  return (
      <>
          <Layout>
          <div className="content-wrapper">
              <div className="page-header">
                  <h3 className="page-title">
                      <span className="page-title-icon bg-gradient-primary text-white me-2">
                          <i className="mdi mdi-home"></i>
                      </span> Dashboard
                  </h3>
                  
              </div>
              <div className="row">
                  <div className="col-md-4 stretch-card grid-margin">
                      <div className="card bg-gradient-danger card-img-holder text-white">
                          <div className="card-body">
                              <img src="assets/images/dashboard/circle.svg" className="card-img-absolute" alt="circle-image" />
                              <h4 className="font-weight-normal mb-3">Weekly Invoice  <i className="mdi mdi-chart-line mdi-24px float-right"></i>
                              </h4>
                              <h2 className="mb-5">$ 1,50,000</h2>
                              <h6 className="card-text">Increased by 60%</h6>
                          </div>
                      </div>
                  </div>
                  <div className="col-md-4 stretch-card grid-margin">
                      <div className="card bg-gradient-info card-img-holder text-white">
                          <div className="card-body">
                              <img src="assets/images/dashboard/circle.svg" className="card-img-absolute" alt="circle-image" />
                              <h4 className="font-weight-normal mb-3">Weekly Quatation <i className="mdi mdi-bookmark-outline mdi-24px float-right"></i>
                              </h4>
                              <h2 className="mb-5">575</h2>
                              <h6 className="card-text">Decreased by 10%</h6>
                          </div>
                      </div>
                  </div>
                  <div className="col-md-4 stretch-card grid-margin">
                      <div className="card bg-gradient-success card-img-holder text-white">
                          <div className="card-body">
                              <img src="assets/images/dashboard/circle.svg" className="card-img-absolute" alt="circle-image" />
                              <h4 className="font-weight-normal mb-3">Weekly Customer <i className="mdi mdi-diamond mdi-24px float-right"></i>
                              </h4>
                              <h2 className="mb-5">250</h2>
                              <h6 className="card-text">Increased by 5%</h6>
                          </div>
                      </div>
                  </div>
              </div>
              <div className="row">
                  <div className="col-md-7 grid-margin stretch-card">
                      <div className="card">
                          <div className="card-body">
                              <div className="clearfix">
                                  <h4 className="card-title float-left">Quatation, Invoice & Customer Statistics</h4>
                                  <div id="visit-sale-chart-legend" className="rounded-legend legend-horizontal legend-top-right float-right"></div>
                              </div>
                              <canvas id="visit-sale-chart" className="mt-4"></canvas>
                          </div>
                      </div>
                  </div>
                  <div className="col-md-5 grid-margin stretch-card">
                      <div className="card">
                          <div className="card-body">
                              <h4 className="card-title">Lead Sources</h4>
                              <canvas id="traffic-chart"></canvas>
                              <div id="traffic-chart-legend" className="rounded-legend legend-vertical legend-bottom-left pt-4"></div>
                          </div>
                      </div>
                  </div>
              </div>
              <div className="row">
                  <div className="col-12 grid-margin">
                      <div className="card">
                          <div className="card-body">
                              <h4 className="card-title">Recent Tickets</h4>
                              <div className="table-responsive">
                                  <table className="table">
                                      <thead>
                                          <tr>
                                              <th> Assignee </th>
                                              <th> Subject </th>
                                              <th> Status </th>
                                              <th> Last Update </th>
                                              <th> Tracking ID </th>
                                          </tr>
                                      </thead>
                                      <tbody>
                                          <tr>
                                              <td>
                                                  <img src="assets/images/faces/face1.jpg" className="me-2" alt="image" /> David Grey
                                              </td>
                                              <td> Pass is not recieved </td>
                                              <td>
                                                  <label className="badge badge-gradient-success">DONE</label>
                                              </td>
                                              <td> Dec 5, 2022 </td>
                                              <td> WD-12345 </td>
                                          </tr>
                                          <tr>
                                              <td>
                                                  <img src="assets/images/faces/face2.jpg" className="me-2" alt="image" /> Stella Johnson
                                              </td>
                                              <td> Please send quatation </td>
                                              <td>
                                                  <label className="badge badge-gradient-warning">PROGRESS</label>
                                              </td>
                                              <td> Jen 12, 2023 </td>
                                              <td> WD-12346 </td>
                                          </tr>
                                          <tr>
                                              <td>
                                                  <img src="assets/images/faces/face3.jpg" className="me-2" alt="image" /> Marina Michel
                                              </td>
                                              <td> Sales' person is not available </td>
                                              <td>
                                                  <label className="badge badge-gradient-info">ON HOLD</label>
                                              </td>
                                              <td> Apr 16, 2023 </td>
                                              <td> WD-12347 </td>
                                          </tr>
                                          <tr>
                                              <td>
                                                  <img src="assets/images/faces/face4.jpg" className="me-2" alt="image" /> John Doe
                                              </td>
                                              <td> Duplicate invoice </td>
                                              <td>
                                                  <label className="badge badge-gradient-danger">REJECTED</label>
                                              </td>
                                              <td> Jun 3, 2023 </td>
                                              <td> WD-12348 </td>
                                          </tr>
                                      </tbody>
                                  </table>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>

              </div>

              </Layout>
         
      </>
  )
}