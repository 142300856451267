import { Link, useNavigate } from 'react-router-dom'
import Layout from '../layout/layout'
import { APICall } from '../utils/utils.js'
import { useState, useEffect } from 'react'
import { useParams } from "react-router-dom";

export default function ViewQuote() {

    const navigate = useNavigate()
    const [message, setMessage] = useState("");
    //const [error, setError] = useState("");
    const [quote, setQuote] = useState(null);
    let { id } = useParams();
    let listItems = [];

    
    useEffect(() => {
       
        if (!id || id===null) {
            id = 0;
        }
       
        viewbooking();


    }, []);

    function viewbooking() {

        var response = APICall(null, 'BookingMasters/GetBookingMasterViewById?bookingMasterId=' + id, 'GET')
            .then(resposnse => {
                console.log('Data', resposnse);
                if (resposnse.StatusCode === 200) {
                    setQuote(resposnse.Result);
                } else {
                    setMessage(resposnse.Message);
                    //setError(resposnse.StatusCode);
                }
                console.log('Data', quote);
            });
    }

    function deleteHandler(did) {
        
        if (window.confirm('Delete this Itinerary?')) {
            
            var response = APICall(null, 'ItineraryDetails/DeleteItineraryDetail?itineraryDetailId=' + did, 'DELETE')
                    .then(resposnse => {
                        console.log('Data', resposnse);
                        if (resposnse.StatusCode === 200) {
                            viewbooking();
                           // setQuote(resposnse.Result);
                            setMessage(resposnse.Message);
                        } else {
                            setMessage(resposnse.Message);
                            //setError(resposnse.StatusCode);
                        }
                        console.log('Data', quote);
                    });
            
        } 
    }


    return (
      <>
            <Layout>

                <div className="content-wrapper">
                    <div className="page-header">
                        <h3 className="page-title">
                            <span className="page-title-icon bg-gradient-primary text-white me-2">
                                <i className="mdi  mdi-book-open"></i>
                            </span>  View Quote
                        </h3>
                        <a class="nav-link1" href="/quotes"><button class="btn btn-primary btn-lg ">Back</button></a>
                    </div>

                    <div className="row">
                        <div className="card">
                            <div className="card-body">
                                <h4 className="card-title">Details</h4>
                                <p className="card-description"> </p>

                                {quote != null && quote?.bookingMasterId >0?
                                    <>
                                        <nav>
                                            <div class="nav nav-tabs" id="nav-tab" role="tablist">
                                                <button class="nav-link active" id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#nav-home" type="button" role="tab" aria-controls="nav-home" aria-selected="true">Customer</button>
                                                <button class="nav-link" id="nav-profile-tab" data-bs-toggle="tab" data-bs-target="#nav-profile" type="button" role="tab" aria-controls="nav-profile" aria-selected="false">Package</button>
                                                <button class="nav-link" id="nav-contact-tab" data-bs-toggle="tab" data-bs-target="#nav-contact" type="button" role="tab" aria-controls="nav-contact" aria-selected="false">Tour Info</button>
                                                <button class="nav-link" id="nav-contact-tab" data-bs-toggle="tab" data-bs-target="#nav-tour" type="button" role="tab" aria-controls="nav-contact" aria-selected="false">Payment</button>
                                            </div>
                                        </nav>
                                        <div class="tab-content" id="nav-tabContent">
                                            <div class="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab"><br />
                                                <div class="form-group row">
                                                    <label for="exampleInputUsername2" class="col-sm-3 col-form-label"> Booking Number</label>
                                                    <div class="col-sm-9">
                                                        {quote.bookingNo}
                                                    </div>
                                                </div>
                                                    <div class="form-group row">
                                                        <label for="exampleInputUsername2" class="col-sm-3 col-form-label"> Customer</label>
                                                        <div class="col-sm-9">
                                                        {quote.customerUser}
                                                        </div>
                                                    </div>
                                                    <div class="form-group row">
                                                        <label for="exampleInputEmail2" class="col-sm-3 col-form-label">Email</label>
                                                        <div class="col-sm-9">
                                                        {quote.customerEmail}
                                                        </div>
                                                    </div>
                                                    <div class="form-group row">
                                                        <label for="exampleInputMobile" class="col-sm-3 col-form-label">Mobile</label>
                                                        <div class="col-sm-9">
                                                        {quote.customerPhones?.length > 0 ? quote.customerPhones[0].mobile:''}
                                                        </div>
                                                    </div>
                                                    <div class="form-group row">
                                                        <label for="exampleInputMobile" class="col-sm-3 col-form-label">Gender</label>
                                                    <div class="col-sm-9"> {quote.customerGender} </div>
                                                    </div>
                                                   

                                                    <div class="form-group row">
                                                        <label for="exampleInputMobile" class="col-sm-3 col-form-label">Assign To</label>
                                                    <div class="col-sm-9"> {quote.assignedToUser} </div>
                                                </div>
                                                <div class="form-group row">
                                                    <label for="exampleInputMobile" class="col-sm-3 col-form-label">Booking Status</label>
                                                    <div class="col-sm-9"> {quote.bookingStatus} </div>
                                                </div>

                                                    <div class="form-check form-check-flat form-check-primary">
                                                        <label class="form-check-label"> </label>
                                                    </div>  
                                            </div>


                                            <div class="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab"><br />

                                               
                                                   
                                                    <div class="form-group row">
                                                        <label for="exampleInputEmail2" class="col-sm-3 col-form-label">Package Name</label>
                                                        <div class="col-sm-9">
                                                        {quote.packageDetail.packageName}
                                                        </div>
                                                    </div>
                                                    <div class="form-group row">
                                                        <label for="exampleInputMobile" class="col-sm-3 col-form-label">Lead Passenger Name </label>
                                                        <div class="col-sm-9">
                                                        {quote.packageDetail.leadPassengerName}
                                                        </div>
                                                    </div>
                                                    <div class="form-group row">
                                                        <label for="exampleInputMobile" class="col-sm-3 col-form-label">Travel Start Date</label>
                                                    <div class="col-sm-9"> {quote.packageDetail.travelStartDt} </div>
                                                    </div>
                                                    <div class="form-group row">
                                                        <label for="exampleInputMobile" class="col-sm-3 col-form-label">Travel End Date</label>
                                                    <div class="col-sm-9"> {quote.packageDetail.travelEndDt} </div>
                                                    </div>
                                                    <div class="form-group row">
                                                        <label for="exampleInputMobile" class="col-sm-3 col-form-label">Departure City</label>
                                                    <div class="col-sm-9"> {quote.packageDetail.departureCity}  </div>
                                                    </div>

                                                    <div class="form-group row">
                                                        <label for="exampleInputMobile" class="col-sm-3 col-form-label">Currency</label>
                                                    <div class="col-sm-9"> {quote.packageDetail.currency} </div>
                                                    </div>

                                                    <div class="form-check form-check-flat form-check-primary">
                                                        <label class="form-check-label"></label>
                                                    </div>
                                            </div>

                                            <div class="tab-pane fade" id="nav-contact" role="tabpanel" aria-labelledby="nav-contact-tab"> <br />

                                                {quote.itineraryDetails != null ?
                                                    quote.itineraryDetails?.length > 0 ? quote.itineraryDetails.map((itinerary) => {

                                                        {
                                                            const dayImg = itinerary.packageDayImages;
                                                            const singleImage = dayImg.split('\n');
                                                            listItems = []
                                                            {
                                                                singleImage != null &&
                                                                    singleImage?.length > 0 ? singleImage.map((dImg) => {

                                                                        listItems.push(<>
                                                                            <img src={dImg} alt="" style={{ width: "200px", height: "95px" }} />&nbsp;
                                                                        </>)
                                                                    }) : <></>
                                                            }
                                                        }
                                                        return <div class="accordion-item">
                                                            <h2 class="accordion-header" id={`heading-${itinerary.dayNo}`}>
                                                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#collapse-${itinerary.dayNo}`} aria-expanded="false" aria-controls="collapseTwo">
                                                                    Day #{itinerary.dayNo}
                                                                </button>
                                                            </h2>
                                                            <div id={`collapse-${itinerary.dayNo}`} class="accordion-collapse collapse" aria-labelledby={`heading-${itinerary.dayNo}`} data-bs-parent="#accordionExample">
                                                                <div class="accordion-body">
                                                                    
                                                                    {itinerary.dayNo == quote.itineraryDetails.length ?
                                                                        <div class="col-sm-1"> <button type="button" class="btn btn-primary btn-lg" onClick={(e) => deleteHandler(itinerary.itineraryDetailId)} >Delete</button>
                                                                        </div>
                                                                        : ''
                                                                    }
                                                                    <div class="form-group row">

                                                                        <label for="exampleInputEmail2" class="col-sm-3 col-form-label">Title</label>
                                                                        <div class="col-sm-9">

                                                                            {itinerary.title}
                                                                        </div>                                                                    </div>
                                                                    <div class="form-group row">
                                                                        <label for="exampleInputMobile" class="col-sm-3 col-form-label">Description</label>
                                                                        <div class="col-sm-9">
                                                                            <div dangerouslySetInnerHTML={{ __html: itinerary.details }} />
                                                                        </div>
                                                                    </div>
                                                                    <div class="form-group row">
                                                                        <label for="exampleInputMobile" class="col-sm-3 col-form-label">Day Images</label>
                                                                        <div class="col-sm-9"> <div className="image-wrapper">{listItems} </div>                                                              
                                                                         </div>
                                                                    </div>
                                                                    <div class="form-group row">
                                                                        <label for="exampleInputMobile" class="col-sm-3 col-form-label">Hotel Name</label>
                                                                        <div class="col-sm-9"> {itinerary.hotelName} </div>
                                                                    </div>
                                                                    <div class="form-group row">
                                                                        <label for="exampleInputMobile" class="col-sm-3 col-form-label">Checkin Date</label>
                                                                        <div class="col-sm-9"> {itinerary.checkInDt}  </div>
                                                                    </div>
                                                                    <div class="form-group row">
                                                                        <label for="exampleInputMobile" class="col-sm-3 col-form-label">Room Type </label>
                                                                        <div class="col-sm-9"> {itinerary.roomType}  </div>
                                                                    </div>
                                                                    <div class="form-group row">
                                                                        <label for="exampleInputMobile" class="col-sm-3 col-form-label">Place</label>
                                                                        <div class="col-sm-9"> {itinerary.place}  </div>
                                                                    </div>
                                                                    <div class="form-group row">
                                                                        <label for="exampleInputMobile" class="col-sm-3 col-form-label">Number of Rooms</label>
                                                                        <div class="col-sm-9"> {itinerary.noOfRooms} </div>
                                                                    </div>
                                                                    <div class="form-group row">
                                                                        <label for="exampleInputMobile" class="col-sm-3 col-form-label">Special Instructions</label>
                                                                        <div class="col-sm-9"> {itinerary.specialInstructions} </div>
                                                                    </div>
                                                                    <div class="form-group row">
                                                                        <label for="exampleInputMobile" class="col-sm-3 col-form-label">Meal Plan </label>
                                                                        <div class="col-sm-9"> {itinerary.mealPlan} </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    }) : <>
                                                            <div className="text-danger">No record found!</div>
                                                        </>

                                                        : <><div  className="text-danger">No record found!</div></>

                                                  }
                                               
                                                   
                                                   
                                               

                                            </div>

                                            <div class="tab-pane fade" id="nav-tour" role="tabpanel" aria-labelledby="nav-contact-tab"> <br />

                                               
                                                    <div class="form-group row">
                                                        <label for="exampleInputUsername2" class="col-sm-3 col-form-label">Package Cost</label>
                                                        <div class="col-sm-9">
                                                        {quote.packageDetail.packageCost}
                                                        </div>
                                                    </div>
                                                   
                                                    <div class="form-group row">
                                                    <label for="exampleInputMobile" class="col-sm-3 col-form-label">Taxable</label>
                                                        <div class="col-sm-9">
                                                        {quote.packageDetail.taxable}
                                                    </div>
                                                </div>
                                                <div class="form-group row">
                                                    <label for="exampleInputMobile" class="col-sm-3 col-form-label">TCS</label>
                                                    <div class="col-sm-9"> {quote.packageDetail.tcs} </div>
                                                </div>
                                                    <div class="form-group row">
                                                        <label for="exampleInputMobile" class="col-sm-3 col-form-label">GST</label>
                                                    <div class="col-sm-9"> {quote.packageDetail.gst} </div>
                                                    </div>

                                                    <div class="form-group row">
                                                        <label for="exampleInputMobile" class="col-sm-3 col-form-label">Total Tax</label>
                                                    <div class="col-sm-9"> {quote.packageDetail.totalTax}  </div>
                                                    </div>
                                                    <div class="form-group row">
                                                        <label for="exampleInputMobile" class="col-sm-3 col-form-label">Final Payable Amount </label>
                                                    <div class="col-sm-9"> {quote.packageDetail.finalCost}  </div>
                                                    </div>

                                                    <div class="form-group row">
                                                        <label for="exampleInputMobile" class="col-sm-3 col-form-label">Payment Status </label>
                                                    <div class="col-sm-9"> {quote.paymentStatus}  </div>
                                                </div>
                                                <div class="form-group row">
                                                    <label for="exampleInputMobile" class="col-sm-3 col-form-label">No of Adult</label>
                                                    <div class="col-sm-9"> {quote.packageDetail.noOfAdult} </div>
                                                </div>
                                                <div class="form-group row">
                                                    <label for="exampleInputMobile" class="col-sm-3 col-form-label">No of Child</label>
                                                    <div class="col-sm-9"> {quote.packageDetail.noOfChild} </div>
                                                </div>
                                                <div class="form-group row">
                                                    <label for="exampleInputMobile" class="col-sm-3 col-form-label">No of Infant</label>
                                                    <div class="col-sm-9"> {quote.packageDetail.noOfInfant} </div>
                                                </div>
                                                    
                                                    <div class="form-check form-check-flat form-check-primary">
                                                        <label class="form-check-label"></label>

                                                    </div>


                                                
                                            </div>



                                        </div>
                                    </>
                                    : <>
                                        <div className="form-check form-check-flat form-check-primary">
                                            <label className="form-check-label text-danger" >No record founbd! {message}</label>

                                            </div>
                                    </>
                                   

                                 } 

                            </div>
                        </div>
                    </div>

                </div>
           

            </Layout>

        </>
    )


}