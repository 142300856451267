import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';

window.baseurl = process.env.REACT_APP_BASE_URL;
window.apieurl = process.env.REACT_APP_API_URL;
window.apikey = process.env.REACT_APP_SITE_API_KEY; 


const baseUrl =  document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

root.render(
  <BrowserRouter basename={baseUrl}>
    <App />
  </BrowserRouter>);

