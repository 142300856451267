import { Link } from 'react-router-dom'
import Layout from '../layout/layout'
import { APICall } from '../utils/utils.js'
import { useState, useEffect, useRef } from 'react'

export default function Customers() {

    const [message, setMessage] = useState("");
    const [error, setError] = useState("");
    const [customerlist, setCustomerList] = useState(null);
    const [cmsg, setCmsg] = useState("");

    const [keyword, setKeyword] = useState('');
   
    const searchRef = useRef();

    useEffect(() => {

        const cmsg = JSON.parse(localStorage.getItem('cmsg'));
        if (cmsg) {
            setCmsg(cmsg);       
            localStorage.removeItem('cmsg')
        }

        getcustomerlist();


    }, []);

    function getcustomerlist() {

        const stData = {
            keyword: '',
            userType: 'C',

        };

        var response = APICall(stData, 'Users/GetUsersByCondition', 'POST')
            .then(resposnse => {
               
                if (resposnse.StatusCode === 200) {
                    setCustomerList(resposnse.Result);
                } else {
                    setMessage(resposnse.Message);
                    setError(resposnse.StatusCode);
                }
                console.log('Data', resposnse);
            });
    }

    function resetHandler() {
        getcustomerlist();

    }

    function submitHandler(e) {
        e.preventDefault();

        const stData = {
            keyword: keyword,
            userType: 'C',
           
        };
       
        
        var response = APICall(stData, 'Users/GetUsersByCondition', 'POST')
            .then(resposnse => {
                console.log('Data', resposnse);
                searchRef.current.value = '';
                if (resposnse.StatusCode == 200) {
                    setCustomerList(resposnse.Result);
                } else {
                    setCustomerList(null);
                    setMessage(resposnse.Message);
                    setError(resposnse.StatusCode);
                }
                console.log('Data', resposnse);
            });
        
        //console.log('You clicked submit.');
    }



    return (
      <>
            <Layout>

                <div class="content-wrapper">
                    <div class="page-header">
                        <h3 class="page-title">
                            <span class="page-title-icon bg-gradient-primary text-white me-2">
                                <i class="mdi  mdi-book-open"></i>
                            </span>  Customer
                        </h3>
                        <a class="nav-link1" href="/create-customer"><button class="btn btn-primary btn-lg ">Create</button></a>
                    </div>



                    <div class="row">
                        <div class="card">
                            <div class="card-body">
                                <h4 class="card-title">Listing</h4>
                                {cmsg ? <div className="text-success"> {cmsg} </div> : null}
                                <form className="pt-3" onSubmit={submitHandler}>
                                    <input type="text" name="keyword" className="form-control form-control-lg" defaultValue={keyword} style={{display: 'inline-block',
                                    width: 200
                                    }} ref={searchRef} onChange={(e) => setKeyword(e.target.value)} required /> <button type="submit" class="btn btn-primary btn-lg " >Search</button> <button type="button" onClick={resetHandler} class="btn btn-primary btn-lg " >Reset</button>
                                    </form>
                            </div>
                            <table class="table table-bordered">
                                <thead>
                                    <tr>
                                        <th> # </th>
                                        <th> Name </th>
                                        <th> Email </th>
                                        <th>Gender </th>
                                       
                                        <th>Status</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {customerlist != null ?
                                        customerlist?.length > 0 ? customerlist.map((customer) => (
                                            <tr>
                                                <td> {customer.userId} </td>
                                                <td> {customer.firstName} {customer.lastName} </td>
                                                <td> {customer.email} </td>
                                                <td> {customer.gender} </td>
                                               
                                                <td> {customer.status} </td>
                                                <td> <a class="nav-link1" href={`/view-customer/${customer.userId}`} title="View"><i class="mdi mdi-tooltip-text icon-md text-primary"></i></a> <a class="nav-link1" href={`/edit-customer/${customer.userId}`} title="Edit"><i class="mdi mdi mdi-grease-pencil icon-md text-primary"></i></a> </td>
                                            </tr>
                                        ))
                                            : <tr>
                                                <td colSpan="6" className="text-danger"><h3>No listing available! { message}</h3></td></tr>

                                        : <tr>
                                            <td colSpan="6" className="text-danger">{message}</td></tr>
                                    }
                                    
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
           

            </Layout>

        </>
    )
}