
export async function APICall(param, apiUrl, method) {

    var token = localStorage.getItem('token');; 
    
    try {
        if (method == 'GET' || method == 'DELETE') {
            
                return await fetch(window.apieurl + apiUrl, {
                    method: method,
                    headers: {
                        'Accept': 'application/json, text/plain',
                        'Content-Type': 'application/json;charset=UTF-8',
                        'XApiKey': window.apikey,
                        'AccessToken': token
                    }
                  
                })
                    .then((respose) => {
                        console.log(respose);
                        return respose.json()
                        //throw new Error('Response is not valid.')
                    })
                    .then((data) => {
                        // console.log(data);
                        return data;
                    })
        } else {
            return await fetch(window.apieurl + apiUrl, {
                method: method,
                headers: {
                    'Accept': 'application/json, text/plain',
                    'Content-Type': 'application/json;charset=UTF-8',
                    'XApiKey': window.apikey,
                    'AccessToken': token
                },
                body: JSON.stringify(param),
            })
                .then((respose) => {
                    console.log(respose);
                    return respose.json()
                    //throw new Error('Response is not valid.')
                })
                .then((data) => {
                    // console.log(data);
                    return data;
                })
        }
    } catch (error) {
        
        //console.log('Er-', error);
        return error;
        
    }
  
}


export function CheckPermission() {

    
    var roleId = sessionStorage.getItem('roleId');
    if (!roleId || roleId == null || roleId!=1) {
        return false;
    }
    return true;

}