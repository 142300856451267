import { useState, useRef,useEffect } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { APICall } from '../utils/utils.js'
import Layout from '../layout/layout'
import "../custom.css"
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export default function EditQuotethree() {

    const navigate = useNavigate()
    const [packageId, setPackageId] = useState('')
    const [bookingMasterId, setBookingMasterId] = useState('')
    const [packageCost, setPackageCost] = useState('')
    const [taxable, setTaxable] = useState('')
    const [gst, setGst] = useState('')
    const [gstper, setGstPer] = useState('')
    const [totalTax, setTotalTax] = useState('') 
    const [finalCost, setFinalCost] = useState('') 
    const [markupPrice, setMarkupPrice] = useState('') 
    const [markupPer, setMarkupPer] = useState('') 
    const [tcsPrice, setTcsPrice] = useState('')
    const [tcsPer, setTcsPer] = useState('') 

    const [noAdult, setAdult] = useState('') 
    const [noChild, setChild] = useState('') 
    const [noInfant, setInfant] = useState('') 
    
    
     
    
    const [paymentStatusId, setPaymentStatusId] = useState("");
    const [paymentStatus, setPaymentStatus] = useState("");

    const [message, setMessage] = useState("");
    const [error, setError] = useState("");

    const [backUrl, setBackUrl] = useState("");
    const bkid = localStorage.getItem('bkid');


    const packageCostRef = useRef();
    const taxableRef = useRef();
    
    const gstperRef = useRef();
    const gstRef = useRef();
    const totalTaxRef = useRef();
    const finalCostRef = useRef();
    const markupPriceRef = useRef();
    const markupPerRef = useRef();
    const tcsPriceRef = useRef();
    const tcsPerRef = useRef();
 

    let { id } = useParams();

   

    useEffect(() => {

              


    }, []);

    const searchRef = useRef();

    useEffect(() => {

        if (!id || id === null) {
            id = 0;
            setBackUrl('quotes')
            setMessage('Invalid quote Id!');
            setError(400);
        } else {
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            });

            if (id > 0) {

               
                setBackUrl('/edit-quote-step-one/' + bkid)
                var response = APICall(null, 'PackageDetails/GetPackageDetailById?packageDetailId='+id, 'GET')
                    .then(resposnse => {
                         
                        if (resposnse.StatusCode === 200) {
                            setBookingMasterId(resposnse.Result.bookingMasterId);
                            setPackageCost(resposnse.Result.packageCost);
                            setTaxable(resposnse.Result.taxable);      
                            setGst(resposnse.Result.gst);
                            setGstPer(resposnse.Result.gstpercent);
                            setTotalTax(resposnse.Result.totalTax);
                            setFinalCost(resposnse.Result.finalCost);
                            setPaymentStatusId(resposnse.Result.paymentStatusId); 
                            setPaymentStatus(resposnse.Result.paymentStatus);
                            setMarkupPer(resposnse.Result.markupPercent)
                            setMarkupPrice(resposnse.Result.markup)
                            setTcsPrice(resposnse.Result.tcs)
                            setTcsPer(resposnse.Result.tcspercent)
                            setAdult(resposnse.Result.noOfAdult)
                            setChild(resposnse.Result.noOfChild)
                            setInfant(resposnse.Result.noOfInfant)
                           
                        } else {
                            setMessage(resposnse.Message);
                            setError(resposnse.StatusCode);
                        }
                        console.log('Data11-', packageCost);
                    });
            }
           
        }
    }, [null]);

    const backhandler = (content) => {

        navigate(backUrl);
    }

   
    const handleChange = (event) => {

        
        console.log(packageCost + ' - ' + taxable + ' = ' + gst);
        let calculateMP = 0;
        calculateMP = (parseFloat(markupPer) * parseFloat(packageCost)) / 100
        

        

        let calculateTCS = 0;
        calculateTCS = (parseFloat(tcsPer) * (parseFloat(packageCost) + parseFloat(calculateMP))) / 100
        setTcsPrice(parseFloat(calculateTCS));


        let gstTemp = 0;
        gstTemp = (parseFloat(gstper) * (parseFloat(calculateMP) + parseFloat(packageCost))) / 100;
        setGst(gstTemp);
        
        let finalamount = parseFloat(taxable) + parseFloat(gstTemp) + parseFloat(calculateTCS);
        let taxamount = parseFloat(gstTemp) + parseFloat(calculateTCS);

        setMarkupPrice(parseFloat(calculateMP));
        setTaxable(parseFloat(calculateMP) + parseFloat(packageCost));
        setMarkupPer(markupPer)
        setTotalTax(taxamount);
        setFinalCost(finalamount);

        tcsPriceRef.current.value = parseFloat(calculateTCS);
        gstRef.current.value = parseFloat(gstTemp);
        totalTaxRef.current.value = taxamount;
        finalCostRef.current.value = finalamount;
    }
   
    function loadAmount() {
       
    }


    const submitHandler = (event) => {
        event.preventDefault()
         createQuoteRequest()
        //console.log('Ed-',new Date(travelEdate))
        //console.log('Sd-',new Date(travelSdate))
        
        
    }


    function createQuoteRequest() {

        const paymentData = {
            bookingMasterId: bookingMasterId,
            packageCost: packageCost,
            taxable: taxable,
            gst: gst,
            totalTax: totalTax, 
            finalCost: finalCost,            
            paymentStatusId: paymentStatusId,            
            markup: markupPrice,
            markupPercent: markupPer,
            gstpercent: gstper,
            noOfAdult: noAdult,
            noOfChild: noChild,
            noOfInfant: noInfant,
            tcspercent: tcsPer,
            tcs: tcsPrice
            
           
        };
        //navigate('/dashboard');

        var response = APICall(paymentData, 'PackageDetails/UpdatePaymentDetail', 'POST')
            .then(resposnse => {
                console.log('Data', resposnse);
                if (resposnse.StatusCode == 201) {
                   localStorage.setItem('cmsg', JSON.stringify('Quote created successfully'));
                    navigate('/quotes');
                    
                } else {
                    setMessage(resposnse.Message);
                    setError(resposnse.StatusCode);
                    
                }
                console.log('Data', resposnse);
            });

    }

    return (
      <>
            <Layout>

                <div class="content-wrapper">
                    <div class="page-header">
                        <h3 class="page-title">
                            <span class="page-title-icon bg-gradient-primary text-white me-2">
                                <i class="mdi  mdi-book-open"></i>
                            </span>  Quotes
                        </h3>
                        <button class="btn btn-primary btn-lg " onClick={(e) => { backhandler() }}>Back</button>
                    </div>



                    <div class="row">
                        <div class="card">
                            <div class="card-body">
                                <h4 class="card-title">Quote - Package Information -  </h4>
                                {id != null ?
                                   <>
                                        <div className="text-danger">{message ? <p>{message}</p> : null}</div>
                                        
                                  
                                
                                <p class="card-description"> </p>
                                <form class="forms-sample" onSubmit={submitHandler}>
                                    <div class="form-group row">
                                                <label for="exampleInputMobile" className="col-sm-3 col-form-label">Package Cost</label>
                                                <div className="col-sm-9"> 
                                                    <input type="text" ref={packageCostRef} class="form-control" id="exampleInputpckcost" placeholder="Package Cost" defaultValue={packageCost} onChange={(e) => setPackageCost(e.target.value)} required />
                                        </div>
                                            </div>
                                            <div class="form-group row">
                                                <label for="exampleInputMobile" class="col-sm-3 col-form-label">Markup %</label>
                                                <div class="col-sm-9">

                                                    <input type="number" ref={markupPerRef} min="0" class="form-control" id="exampleInputmper" placeholder="Markup %" defaultValue={markupPer} onChange={(e) => setMarkupPer(e.target.value)} required />
                                                </div>
                                            </div>
                                        <div class="form-group row">
                                            <label for="exampleInputmp" className="col-sm-3 col-form-label">Markup Price</label>
                                            <div className="col-sm-9">
                                                    <input type="text" ref={markupPriceRef} class="form-control" id="exampleInputpmp" placeholder="Markup Price" defaultValue={markupPrice} onChange={(e) => setMarkupPrice(e.target.value)} readOnly />
                                            </div>
                                            </div>
                                            <div class="form-group row">
                                                <label for="exampleInputEmail2" className="col-sm-3 col-form-label">Taxable</label>
                                                <div class="col-sm-9">
                                                    <input type="number" ref={taxableRef} readOnly min="0" class="form-control" id="exampleInputtaxable" placeholder="Taxable" defaultValue={taxable} onChange={(e) => setTaxable(e.target.value)} required />

                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <label for="exampleInputMobile" class="col-sm-3 col-form-label">TCS %</label>
                                                <div class="col-sm-9">

                                                    <input type="number" ref={tcsPerRef} min="0" class="form-control" id="exampleInputtcsper" placeholder="TCS %" defaultValue={tcsPer} onChange={(e) => setTcsPer(e.target.value)} required />
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <label for="exampleInputmp" className="col-sm-3 col-form-label">TCS Price</label>
                                                <div className="col-sm-9">
                                                    <input type="text" ref={tcsPriceRef} class="form-control" id="exampleInputtcspri" placeholder="TCS Price" defaultValue={tcsPrice} onChange={(e) => setTcsPrice(e.target.value)} readOnly />
                                                </div>
                                            </div>
                                    
                                   
                                    <div class="form-group row">
                                                <label for="exampleInputMobile" class="col-sm-3 col-form-label">GST %</label>
                                        <div class="col-sm-9">
                                                    
                                                    <select class="form-control" id="exampleSelectPaymentStatus" value={gstper} onChange={(e) => setGstPer(e.target.value)} required>
                                                        <option value="">Select</option>
                                                        <option value="5">5%</option>
                                                        <option value="12">12%</option>
                                                        <option value="18">18%</option>
                                                    </select>
                                        </div>
                                     </div>
                                        <div class="form-group row">
                                            <label for="exampleInputMobile" class="col-sm-3 col-form-label"></label>
                                            <div class="col-sm-9">
                                                    <button type="button" class="btn btn-gradient-primary me-2" onClick={handleChange}>Calculate</button>
                                            </div>


                                            </div>
                                            <div class="form-group row">
                                                <label for="exampleInputgst" class="col-sm-3 col-form-label">GST Tax</label>
                                                <div class="col-sm-9">
                                                    
                                                    <input type="number" readOnly min="0" ref={gstRef} defaultValue={gst} class="form-control" id="exampleInputtgst" placeholder="GST" />

                                                </div>


                                            </div>
                                    <div class="form-group row">
                                                <label for="exampleInputMobile" class="col-sm-3 col-form-label">Total Tax</label>
                                                <div class="col-sm-9">
                                                    
                                                    <input type="number" readOnly min="0" ref={totalTaxRef} defaultValue={totalTax} class="form-control" id="exampleInputtax" placeholder="Tax" />

                                                </div>

                                                    
                                    </div>
                                    <div class="form-group row">
                                                <label for="exampleInputMobile" class="col-sm-3 col-form-label">Final Payable Amount</label>
                                                <div class="col-sm-9">
                                                    
                                                    <input type="number" readOnly min="0" ref={finalCostRef}  defaultValue={finalCost} class="form-control" id="exampleInputtot" placeholder="Total" />

                                                </div>
                                     </div>     
                                            <div class="form-group row">
                                                <label for="exampleInputMobile" class="col-sm-3 col-form-label">No of Adult</label>
                                                <div class="col-sm-9">

                                                    <input type="number" min="0" class="form-control" id="exampleInputadult" placeholder="Adult" defaultValue={noAdult} onChange={(e) => setAdult(e.target.value)} required />
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <label for="exampleInputMobile" class="col-sm-3 col-form-label">No of Child</label>
                                                <div class="col-sm-9">

                                                    <input type="number" min="0" class="form-control" id="exampleInputchild" placeholder="Child" defaultValue={noChild} onChange={(e) => setChild(e.target.value)} required />
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <label for="exampleInputMobile" class="col-sm-3 col-form-label">No of Infant</label>
                                                <div class="col-sm-9">

                                                    <input type="number" min="0" class="form-control" id="exampleInputInfant" placeholder="Infant" defaultValue={noInfant} onChange={(e) => setInfant(e.target.value)} required />
                                                </div>
                                            </div>

                                            
                                   
                                    <div class="form-group row">
                                                <label for="exampleInputMobile" class="col-sm-3 col-form-label">Payment Status </label>
                                                <div class="col-sm-9"> {paymentStatus} <br /><select class="form-control" id="exampleSelectPaymentStatus" value={paymentStatusId} onChange={(e) => setPaymentStatusId(e.target.value)} required>
                                            <option value="">Select</option>
                                            <option value="1">Paid</option>
                                            <option value="2">Unpaid</option>
                                           
                                        </select> </div>
                                    </div>

                                    <div class="form-check form-check-flat form-check-primary">
                                        <label class="form-check-label" />

                                            </div>
                                   
                                    <button type="submit" class="btn btn-gradient-primary me-2">Submit</button>

                                        </form>
                                    </>
                                : 
                                    <div class="form-check form-check-flat form-check-primary text-danger">
                                       
                                    {message ? <p>{message}</p> : null}
                                </div>
                                }
                            </div>
                        </div>
                    </div>

                </div>

            </Layout>

        </>
    )
}