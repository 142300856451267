import { useState, useRef,useEffect } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { APICall } from '../utils/utils.js'
import Layout from '../layout/layout'
import "../custom.css"
import "../custom.js"
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import SunEditor, { buttonList } from "suneditor-react";
import 'suneditor/dist/css/suneditor.min.css'; // Import Sun Editor's CSS File



export default function EditQuoteTwo() {

    const navigate = useNavigate()
    const [packageId, setPackageId] = useState('')
    const [title, setTitle] = useState('')
    const [description, setDescription] = useState('')
    const [checkindate, setCheckindate] = useState('')
    const [booknum, setBookNum] = useState('')
    const [hotelname, setHotelName] = useState('')
    const [roomtype, setRoomType] = useState('')   
    const [place, setPlace] = useState('') 
    const [numroom, setNumRoom] = useState('')
    const [numnights, setNumNights] = useState('')
    const [specialinst, setSpecialInst] = useState('')
    const [mealplan, setMealPlan] = useState('')
    const [itineraryDetailId, setItineraryDetailId] = useState(0)
    const [dayimages, setDayImages] = useState(0)
    
    
    const [message, setMessage] = useState("");
    const [error, setError] = useState("");


    const titleRef = useRef();
    const checkindateRef = useRef();
    const hotelnameRef = useRef();
    const placeRef = useRef();
    const numroomRef = useRef();
    const numnightsRef = useRef();
    const specialinstRef = useRef();
    const dayimagesRef = useRef(); 

    let { id, day } = useParams();

    const [backUrl, setBackUrl] = useState("");    
    const bkid = localStorage.getItem('bkid');
    let hpid = localStorage.getItem('hpid');
    
    useEffect(() => {

       
        
        //const bnum = localStorage.getItem('booknum');
        //if (bnum) {
        //    setBookNum(bnum);
        //}
        const bkid = localStorage.getItem('bkid');

        var response = APICall(null, 'BookingMasters/GetBookingMasterViewById?bookingMasterId=' + bkid, 'GET')
            .then(resposnse => {

                if (resposnse.StatusCode === 200) {
                    // setfiltered(resposnse.Result);
                    setBookNum(resposnse.Result.bookingNo);
                } else {
                    // setMessage(resposnse.Message);
                    //setError(resposnse.StatusCode);
                }
                console.log('Data', resposnse);
            });

       


    }, []);

    const searchRef = useRef();

    useEffect(() => {

        if (!id || id === null || !day || day === null || !hpid || hpid === null) {
            id = 0;
            setBackUrl('quotes')
            setMessage('Invalid quote id or day!');
            setError(400);
        } else {
            
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
            setBackUrl('/edit-quote-step-one/' + bkid)
          
            setPackageId(id);

            var response = APICall(null, 'ItineraryDetails/GetItineraryDetailByDayNo?packageDetailId=' + id + '&dayNo=' + day, 'GET')
                .then(resposnse => {

                    if (resposnse.StatusCode === 200) {
                        setTitle(resposnse.Result.title); titleRef.current.value = resposnse.Result.title;
                        setDescription(resposnse.Result.details);
                        setCheckindate(new Date(resposnse.Result.checkInDt));
                        setHotelName(resposnse.Result.hotelName);
                        setRoomType(resposnse.Result.roomType);
                        setPlace(resposnse.Result.place);
                        setNumRoom(resposnse.Result.noOfRooms);
                        setNumNights(resposnse.Result.noOfNights);
                        setSpecialInst(resposnse.Result.specialInstructions);
                        setMealPlan(resposnse.Result.mealPlan);
                        setItineraryDetailId(resposnse.Result.itineraryDetailId);
                        setDayImages(resposnse.Result.packageDayImages); 

                    } else {
                         getPackage(hpid,day)
                        //setMessage(resposnse.Message);
                        //setError(resposnse.StatusCode);
                    }
                    //console.log('Data11-', packageCost);
                });
           
        }
    }, [null]);

    function getPackage(pkId,dayno) {

        var response = APICall(null, 'HolidayPackages/GetHolidayPackageDayByDayNo?holidayPackageId=' + pkId + '&dayNo=' + dayno, 'GET')
            .then(resposnse => {

                if (resposnse.StatusCode === 200) {
                    setTitle(resposnse.Result.heading); titleRef.current.value = resposnse.Result.heading;
                    setDescription(resposnse.Result.content);
                    let dimage = resposnse.Result.holidayPackageDayDocuments;
                    let listItems = '';
                   
                    for (let j = 0; j < dimage.length; j++) {
                        listItems = listItems + 'https://crm.vacationum.com/img/' + dimage[j].documentName+'\n'
                    }
                    console.log('Images-', dimage);
                    setDayImages(listItems);

                } else {
                    //setMessage(resposnse.Message);
                    //setError(resposnse.StatusCode);
                }
                //console.log('Data11-', packageCost);
            });
    }

    const backhandler = (content) => {
        
        navigate(backUrl);
    }
    
   
    const handleChange = (content) => {
        setDescription(content)
        //console.log(content); //Get Content Inside Editor
    }


    const submitHandler = (event) => {
        event.preventDefault()
         createitenRequest()

       // navigate('/edit-quote-step-two/' + id + '/2');
       
    }


    function createitenRequest() {

        const itenData = {
            title: title,
            details: description,
            checkInDt: checkindate,
            packageDetailId: packageId,
            hotelName: hotelname, 
            roomType: roomtype,            
            place: place,            
            noOfRooms: numroom,
            noOfNights: numnights,
            specialInstructions: specialinst,
            mealPlan: mealplan,
            dayNo: day,
            itineraryDetailId: itineraryDetailId,
            Photo: 'No photo',
            
            packageDayImages: dayimages
        };
        console.log(itenData);
        //navigate('/dashboard');
       

        var response = APICall(itenData, 'ItineraryDetails/CreateUpdateItineraryDetail', 'POST')
            .then(resposnse => {
                console.log('Data', resposnse);
                if (resposnse.StatusCode == 201) {

                    titleRef.current.value = '';
                    setDescription('');
                    setCheckindate('');
                    hotelnameRef.current.value = '';
                    placeRef.current.value = '';
                    numroomRef.current.value = '';
                    numnightsRef.current.value = '';
                    specialinstRef.current.value = '';
                    setMealPlan('');
                    //setItineraryDetailId('');

                    const tday = localStorage.getItem('day');
                    day = parseInt(day) + 1;
                    if (day <= tday) {

                        navigate('/edit-quote-step-two/' + packageId + '/' + day);
                        window.location.reload();
                       
                    } else {
                        navigate('/edit-quote-step-three/' + packageId);
                    }
                    
                } else {
                    setMessage(resposnse.Message);
                    setError(resposnse.StatusCode);
                    //localStorage.setItem('cmsg', JSON.stringify('Lead created successfully'));
                    //navigate('/leads');
                }
                console.log('Data', resposnse);
            });

    }

    return (
      <>
            <Layout>

                <div class="content-wrapper">
                    <div class="page-header">
                        <h3 class="page-title">
                            <span class="page-title-icon bg-gradient-primary text-white me-2">
                                <i class="mdi  mdi-book-open"></i>
                            </span>  Quotes
                        </h3>
                        <button class="btn btn-primary btn-lg " onClick={(e) => { backhandler() }}>Back</button>
                    </div>



                    <div class="row">
                        <div class="card">
                            <div class="card-body">
                                <h4 class="card-title">Quote - Package Day Waise Information - {booknum}</h4>
                                {id != null ?
                                   <>
                                        <div className="text-danger">{message ? <p>{message}</p> : null}</div>
                                        
                                  
                                
                                <p class="card-description"> </p>
                                <form class="forms-sample" onSubmit={submitHandler}>
                                    <div class="form-group row">
                                                <label for="exampleInputMobile" class="col-sm-3 col-form-label">Day</label>
                                                <div className="col-sm-9"> { day} </div>
                                        
                                    </div>
                                    
                                    <div class="form-group row">
    
                                                <label for="exampleInputEmail2" class="col-sm-3 col-form-label">Title</label>
                                        <div class="col-sm-9">
                                                    <input type="text" class="form-control" id="exampleInputTitle" placeholder="Title" ref={titleRef} defaultValue={title} onChange={(e) => setTitle(e.target.value)} required />
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                                <label for="exampleInputMobile" class="col-sm-3 col-form-label">Description </label>
                                        <div class="col-sm-9">
                                                    <SunEditor setOptions={{
                                                        height: 200,
                                                        showPathLabel: false,
                                                        charCounter: true,
                                                        maxCharCount: 3000,
                                                        maxWidth: '700px',
                                                        width: 'auto',
                                                        height: 'auto',
                                                        minHeight: '100px',
                                                        maxHeight: '270px',
                                                        buttonList: [
                                                            ['undo', 'redo', 'font', 'fontSize', 'formatBlock'],
                                                            ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript', 'removeFormat'],
                                                            '/', //Line break
                                                            ['fontColor', 'hiliteColor', 'outdent', 'indent', 'align', 'horizontalRule', 'list', 'table'],
                                                            ['link', 'image', 'fullScreen', 'showBlocks', 'codeView', 'preview', 'print', 'save']
                                                        ] // Or Array of button list, eg. [['font', 'align'], ['image']]
                                                        // plugins: [font] set plugins, all plugins are set by default
                                                        // Other option
                                                    }} onChange={handleChange} setContents={description } /> 
                                        </div>
                                            </div>
                                    <div class="form-group row">
                                                <label for="exampleInputMobile" class="col-sm-3 col-form-label">Day Images</label>
                                                <div class="col-sm-9"> <textarea class="form-control" style={{ height:'80px' }} id="dayimages" placeholder="Day Images" ref={dayimagesRef} defaultValue={dayimages} onChange={(e) => setDayImages(e.target.value)} ></textarea>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                                <label for="exampleInputMobile" class="col-sm-3 col-form-label">Hotel Name</label>
                                                <div class="col-sm-9">
                                                    <input type="text" class="form-control" id="exampleInputHotel" placeholder="Hotel Name" ref={hotelnameRef} defaultValue={hotelname} onChange={(e) => setHotelName(e.target.value)} required />
                                                </div>                                                    
                                    </div>
                                    <div class="form-group row">
                                                <label for="exampleInputMobile" class="col-sm-3 col-form-label">Checkin Date</label>
                                                <div class="col-sm-9"> <DatePicker showIcon 
                                                    className="form-control" ref={checkindateRef}
                                                    dateFormat="dd/MM/yyyy"
                                                    
                                                    selected={checkindate}
                                                    onChange={(date) => setCheckindate(date)}
                                                    required
                                                /> </div>
                                            </div>

                                            <div class="form-group row">
                                                <label for="exampleInputMobile" class="col-sm-3 col-form-label">Room Type </label>
                                                <div class="col-sm-9">  <br /><select class="form-control" value={roomtype } id="exampleSelectroomtype" onChange={(e) => setRoomType(e.target.value)} required>
                                                    <option value="">Select</option>
                                                    <option value="1">Deluxe</option>
                                                    <option value="2">Standard</option>
                                                    <option value="3">Super Deluxe</option>
                                                    <option value="4">Luxury</option>
                                                    <option value="5">Family Suite</option>
                                                </select> </div>
                                            </div>
                                    <div class="form-group row">
                                                <label for="exampleInputMobile" class="col-sm-3 col-form-label">Place</label>
                                                <div class="col-sm-9"> <input type="text" class="form-control" id="exampleInputPlace" ref={placeRef} placeholder="Place" defaultValue={place} onChange={(e) => setPlace(e.target.value)} required /> </div>
                                    </div>
                                            <div class="form-group row">
                                                <label for="exampleInputMobile" class="col-sm-3 col-form-label">Number of Rooms</label>
                                                <div class="col-sm-9"> <input type="number" min="1" class="form-control" id="exampleInputnumroom" placeholder="Number of Rooms" ref={numroomRef} defaultValue={numroom} onChange={(e) => setNumRoom(e.target.value)} required /> </div>
                                            </div>
                                            <div class="form-group row">
                                                <label for="exampleInputMobile" class="col-sm-3 col-form-label">Number of Nights</label>
                                                <div class="col-sm-9"> <input type="number" min="1" class="form-control" id="exampleInputnumnights" placeholder="Number of Nights" ref={numnightsRef} defaultValue={numnights} onChange={(e) => setNumNights(e.target.value)} required /> </div>
                                            </div>
                                            <div class="form-group row">
                                                <label for="exampleInputMobile" class="col-sm-3 col-form-label">Special Instructions</label>
                                                <div class="col-sm-9"> <textarea class="form-control" id="specialinst" placeholder="Special Instructions" ref={specialinstRef} defaultValue={specialinst} onChange={(e) => setSpecialInst(e.target.value)} ></textarea>
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <label for="exampleInputMobile" class="col-sm-3 col-form-label">Meal Plan </label>
                                                <div class="col-sm-9">  <br /><select class="form-control" value={mealplan} id="exampleSelectmealplan" onChange={(e) => setMealPlan(e.target.value)}  required>
                                                    <option value="">Select</option>
                                                    <option value="1">No meal</option>
                                                    <option value="2">One Meal (Breakfast)</option>
                                                    <option value="3">Two Meal(Breakfast, Lunch/Dinner)</option>
                                                    <option value="4">All Meal(Breakfast, Lunch & Dinner)</option>
                                                </select> </div>
                                            </div>

                                    <div class="form-check form-check-flat form-check-primary">
                                        <label class="form-check-label" />

                                            </div>
                                   
                                    <button type="submit" class="btn btn-gradient-primary me-2">Next</button>

                                        </form>
                                    </>
                                : 
                                    <div class="form-check form-check-flat form-check-primary text-danger">
                                       
                                    {message ? <p>{message}</p> : null}
                                </div>
                                }
                            </div>
                        </div>
                    </div>

                </div>

            </Layout>

        </>
    )
}