import { Link } from 'react-router-dom'
import Layout from '../layout/layout'
import { APICall } from '../utils/utils.js'
import { useState, useEffect, useRef } from 'react'
import { format } from 'date-fns';
import Pagination from '../components/Pagination';
export default function Quotes() {

    const [message, setMessage] = useState("");
    const [error, setError] = useState("");
    const [quotes, setQuotes] = useState(null);
    const [cmsg, setCmsg] = useState("");

    const [keyword, setKeyword] = useState('');
    //const [leadStatusId, setLeadStatusId] = useState(''); 

    const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage] = useState(50);
    const [totalRecordsCount, setTotalRecordCount] = useState(0);

    const currentRecords = quotes != null ? quotes : null;
    const nPages = quotes != null ? Math.ceil(totalRecordsCount / recordsPerPage) : 0;

    var roleId = sessionStorage.getItem('roleId');

    const quoteData = {
        keyword: '',
        pageSize: recordsPerPage,
        PageNumber: currentPage,

    };
    function onPageChange(pageNumber) {

        quoteData.PageNumber = pageNumber;
        getBookinglist();
    }

    function resetPaging() {
        setCurrentPage(1);
    }
  
    const searchRef = useRef();
    
    useEffect(() => {

        if (localStorage.getItem('cmsg') != 'undefined') {
            const cmsg = JSON.parse(localStorage.getItem('cmsg'));
            if (cmsg) {
                setCmsg(cmsg);
                localStorage.removeItem('cmsg')
            }
        }
       
       
        getBookinglist();

    }, []);

  

    function getBookinglist() {


        var response = APICall(quoteData, 'BookingMasters/GetBookingByCondition', 'POST')
            .then(resposnse => {
                // console.log('Data', resposnse);
                if (resposnse.StatusCode === 200) {
                    setQuotes(resposnse.Result.bookingMasters);
                    setTotalRecordCount(resposnse.Result.totalCount);
                } else {
                    setMessage(resposnse.Message);
                    setError(resposnse.StatusCode);
                }
                console.log('Data', resposnse);
            });
    }
    function deleteQuote(bookingId, event) {
    if (window.confirm('Are you sure, you want to delete quotation?')) {

        const quoteData = {
            bookingId: bookingId
        }
        var response = APICall(quoteData, 'BookingMasters/DeleteQuote?bookingId=' + bookingId, 'DELETE')
            .then(resposnse => {
                console.log('Data', resposnse);
                if (resposnse.StatusCode === 200) {
                    getBookinglist()
                    setMessage(resposnse.Message);
                } else {
                    setMessage(resposnse.Message);
                }

            });

        } else {

            event.target.value = '';
        }
    }
    function updateHandler(bookingId,event) {

        if (window.confirm('Are you sure, you want to update booking status?')) {

            const quoteData = {
                bookingMasterId: bookingId,
                bookingStatusId: event.target.value,
            }
            
            var response = APICall(quoteData, 'BookingMasters/UpdateBookingStatus', 'POST')
                .then(resposnse => {
                    console.log('Data', resposnse);
                    if (resposnse.StatusCode === 200) {
                        getBookinglist()
                        // setQuote(resposnse.Result);
                        setMessage(resposnse.Message);
                    } else {
                        setMessage(resposnse.Message);
                        //setError(resposnse.StatusCode);
                    }
                    
                });

        } else {
            
            event.target.value = '';
        }
    }

    function resetHandler() {
        getBookinglist();

    }
    
    function submitHandler(e) {
        e.preventDefault();

        const quoteData = {
            keyword: keyword,
            pageSize: recordsPerPage,
            PageNumber: currentPage,
           
        };
        //setKeyword('');
        
        var response = APICall(quoteData, 'BookingMasters/GetBookingByCondition', 'POST')
            .then(resposnse => {
                console.log('Data', resposnse);
                searchRef.current.value = '';
                setKeyword('');
                if (resposnse.StatusCode === 200) {
                    setQuotes(resposnse.Result.bookingMasters);
                    setTotalRecordCount(resposnse.Result.totalCount);
                    
                } else {
                    setQuotes(null);
                    setMessage(resposnse.Message);
                    setError(resposnse.StatusCode);
                }
                console.log('Data', resposnse);
            });

        console.log('You clicked submit.');
    }



    return (
      <>
            <Layout>

                <div class="content-wrapper">
                    <div class="page-header">
                        <h3 class="page-title">
                            <span class="page-title-icon bg-gradient-primary text-white me-2">
                                <i class="mdi  mdi-book-open"></i>
                            </span>  Quotes
                        </h3>
                        <a class="nav-link1" href="/new-quote"><button class="btn btn-primary btn-lg ">Create</button></a>
                    </div>



                    <div class="row">
                        <div class="card">
                            <div class="card-body">
                                <h4 class="card-title">Listing</h4>
                                {cmsg ? <div className="text-success"> {cmsg} </div> : null}
                                <form className="pt-3" onSubmit={submitHandler}>
                                    <input type="text" name="keyword" id="keyword" defaultValue={keyword} className="form-control form-control-lg" style={{display: 'inline-block',
                                    width: 200
                                    }} ref={searchRef} onChange={(e) => setKeyword(e.target.value)} required /> <button type="submit" class="btn btn-primary btn-lg " >Search</button> <button type="button" onClick={resetHandler} class="btn btn-primary btn-lg " >Reset</button>
                                    </form>
                            </div>
                            <div className="quotelist">
                            <table class="table table-bordered">
                                <thead>
                                      <tr>
                                        <th> # </th>
                                        <th> Quote Num </th>
                                        <th> Name </th>
                                        <th> Customer Type </th>
                                        <th> Status </th>
                                        <th> Payment Status </th>
                                        <th>Assign to</th>
                                        <th>Booking Date</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                        {currentRecords != null ?
                                            currentRecords?.length > 0 ? currentRecords.map((quote) => (
                                            <tr>
                                                <td> {quote.bookingMasterId} </td>
                                                <td> {quote.bookingNo} </td>
                                                <td> {quote.customerUser}  </td>
                                                <td> {quote.customerType=='L'?'Lead':'Customer'}  </td>
                                                <td> <label class="badge badge-info">{quote.bookingStatus}</label>
                                                    <select class="form-control" id="exampleSelectstatus" onChange={(e) => updateHandler(quote.bookingMasterId, e)}  required>
                                                        <option value="">Update Status</option>
                                                        <option value="1">ManagerRequired</option>
                                                        <option value="2">PaymentFull</option>
                                                        <option value="3">AllBookingDetailsSent</option>
                                                        <option value="4">TravelCompleted</option>
                                                         <option value="5">New</option>
                                                    </select>
                                                </td>
                                                <td> {quote.paymentStatus}  </td>
                                                <td>{quote.assignedToUser} </td>
                                                <td> {format(new Date(quote.bookingDate), 'dd/MM/yyyy')}  </td>
                                               
                                                    <td> <a class="nav-link1" href={`/pdf/${quote.bookingMasterId}`} target="_blank" title="PDF"><i class="mdi  mdi mdi-file-pdf  icon-md text-primary"></i></a> <a class="nav-link1" href={`/view-quote/${quote.bookingMasterId}`} title="View"><i class="mdi mdi-tooltip-text icon-md text-primary"></i></a> <a class="nav-link1" href={`/holiday/${quote.bookingMasterId}`} title="Edit"><i class="mdi mdi mdi-grease-pencil icon-md text-primary"></i></a>
                                                        {roleId == 1 ?
                                                        <a class="nav-link1" onClick={(e) => deleteQuote(quote.bookingMasterId, e)} title="Delete"><i class="mdi mdi mdi-delete icon-md text-primary"></i></a> 
                                                            : ""}
                                                        </td>
                                            </tr>
                                        ))
                                            : <tr>
                                                <td colSpan="6" className="text-danger"><h3>No listing available! { message}</h3></td></tr>

                                        : <tr>
                                            <td colSpan="6" className="text-danger">{message}</td></tr>
                                    }
                                    
                                </tbody>
                                </table>
                                <Pagination
                                    nPages={nPages}
                                    currentPage={currentPage}
                                    setCurrentPage={setCurrentPage}
                                    onPageChange={onPageChange}
                                />
                            </div>
                        </div>
                    </div>
                </div>

           

            </Layout>

        </>
    )
}