import { useEffect } from "react";
import React from "react";
import Leftmenu from './Leftmenu'
import Header from './Header'
import Footer from './Footer'
import { useNavigate, Redirect } from 'react-router-dom';


// Pass the child props
export default function Layout({ children }) {

    const navigate = useNavigate();
    useEffect(() => {
        
        var token = sessionStorage.getItem('token'); 
        if (!token && token == null) {
             navigate("/");
        }
    }, []);

    return (
        <>
            <div className="container-scroller">
                <Header />
                <div className="container-fluid page-body-wrapper">
                    <Leftmenu />
                    <div className="main-panel">
                    {/* display the child prop */}
                        {children}
                        <Footer />
                    </div>
                </div>
            </div>
        </>
    );
}