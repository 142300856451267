import { Route, Routes } from 'react-router-dom'
import Home from './pages/home'
import Dashboard from './pages/dashboard'
import Leads from './pages/leads'
import CreateLead from './pages/createlead'
import ViewLead from './pages/viewlead'
import EditLead from './pages/editlead'
import CreateQuote from './pages/createquote'
import EditQuoteOne from './pages/editquoteone'
import EditQuoteTwo from './pages/editquotetwo'
import EditQuoteThree from './pages/editquotethree'
import Quotes from './pages/quotes'
import ViewQuote from './pages/viewquote'
import Pdf from './pages/pdf'
import Staff from './pages/staff'
import CreateStaff from './pages/createstaff'
import ViewStaff from './pages/viewstaff'
import EditStaff from './pages/editstaff'
import Customers from './pages/customers'
import ViewCustomer from './pages/viewcustomer'
import CreateCustomer from './pages/createcustomer'
import EditCustomer from './pages/editcustomer'
import Profile from './pages/profile'

import NewQuote from './pages/newquote'
import Holiday from './pages/createholiday'

import Issue from './pages/issue'
import DeleteLead from './pages/deletelead'
import DeleteQuote from './pages/deletequote'



function App() {
    return (
        <div className="App">
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/leads" element={<Leads />} />
                <Route path="/create-lead" element={<CreateLead />} />
                <Route path="/view-lead" element={<ViewLead />} />
                <Route path="/view-lead/:id" element={<ViewLead />} />
                <Route path="/edit-lead" element={<EditLead />} />
                <Route path="/edit-lead/:id" element={<EditLead />} />
                <Route path="/delete-lead" element={<DeleteLead />} />
                <Route path="/delete-lead/:id" element={<DeleteLead />} />

                <Route path="/quotes" element={<Quotes />} />
                <Route path="/create-quote" element={<CreateQuote />} />
                <Route path="/edit-quote-step-one" element={<EditQuoteOne />} />
                <Route path="/edit-quote-step-one/:id" element={<EditQuoteOne />} />
                <Route path="/edit-quote-step-two" element={<EditQuoteTwo />} />
                <Route path="/edit-quote-step-two/:id/:day" element={<EditQuoteTwo />} />
                <Route path="/edit-quote-step-two" element={<EditQuoteTwo />} />
                <Route path="/edit-quote-step-three/:id" element={<EditQuoteThree />} />
                <Route path="/view-quote" element={<ViewQuote />} />
                <Route path="/view-quote/:id" element={<ViewQuote />} />
                <Route path="/delete-quote" element={<DeleteQuote />} />
                <Route path="/delete-quote/:id" element={<DeleteQuote />} />
                <Route path="/pdf/:id" element={<Pdf />} />

                <Route path="/new-quote" element={<NewQuote />} />
                <Route path="/holiday" element={<Holiday />} />
                <Route path="/holiday/:id" element={<Holiday />} />

                <Route path="/staff" element={<Staff />} />
                <Route path="/create-staff" element={<CreateStaff />} />
                <Route path="/view-staff" element={<ViewStaff />} />
                <Route path="/view-staff/:id" element={<ViewStaff />} />
                <Route path="/edit-staff" element={<EditStaff />} />
                <Route path="/edit-staff/:id" element={<EditStaff />} />
                

                <Route path="/customers" element={<Customers />} />
                <Route path="/view-customer" element={<ViewCustomer />} />
                <Route path="/view-customer/:id" element={<ViewCustomer />} />
                <Route path="/create-customer" element={<CreateCustomer />} />
                <Route path="/edit-customer" element={<EditCustomer />} />
                <Route path="/edit-customer/:id" element={<EditCustomer />} />

                <Route path="/profile" element={<Profile />} />
                <Route path="/issues" element={<Issue />} />

            </Routes>
        </div>
    )
}
export default App