import { useState, useEffect, useRef } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Layout from '../layout/layout'


export default function Issue() {

    const navigate = useNavigate()
    
    

    let message = "Page is not available or you don't have permission to access this!";

   

    return (
      <>
            <Layout>

                <div class="content-wrapper">
                    <div class="page-header">
                        <h3 class="page-title">
                            <span class="page-title-icon bg-gradient-primary text-white me-2">
                                <i class="mdi  mdi-book-open"></i>
                            </span>  Error
                        </h3>
                        
                    </div>



                    <div class="row">
                        <div class="card">
                            <div class="card-body">
                                <h4 class="card-title">Some Error</h4>
                                
                                    <div className="text-danger">{message ? <p>{message}</p> : null}</div>
                                    
                                
                            </div>
                        </div>
                    </div>

                </div>

            </Layout>

        </>
    )
}