import { useState, useEffect } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { APICall, CheckPermission } from '../utils/utils.js'
import Layout from '../layout/layout'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export default function EditStaff() {

    const navigate = useNavigate()
    const [email, setEmail] = useState('')
    const [fname, setFName] = useState('')
    const [lname, setLName] = useState('')
    const [gender, setGender] = useState('')
    const [dobdate, setDobDate] = useState('')  
    const [userType, setUserType] = useState('') 
    const [status, setStatus] = useState('')
    const [role, setRole] = useState('')
    const [designation, setDesignation] = useState('')
        
    const [message, setMessage] = useState("");
    const [error, setError] = useState("");

    let { id } = useParams();

    useEffect(() => {

        if (!CheckPermission()) {
            navigate('/issues')
        }

        if (!id || id === null) {
            id = 0;
            setMessage('Invalid user Id!');
            setError(400);
        } else {


            var response = APICall(null, 'Users/GetUserById/' + id, 'GET')
                .then(resposnse => {
                    // console.log('Data', resposnse);
                    if (resposnse.StatusCode === 200) {
                        setEmail(resposnse.Result.email);
                        setFName(resposnse.Result.firstName);
                        setLName(resposnse.Result.lastName);
                        setGender(resposnse.Result.gender);
                        setDobDate(new Date(resposnse.Result.dateOfBirth));
                        setStatus(resposnse.Result.statusId);
                        setRole(resposnse.Result.userRoles[0].roleId)
                        setDesignation(resposnse.Result.designation)
                        
                    } else {
                         setMessage(resposnse.Message);
                        setError(resposnse.StatusCode);
                    }
                    console.log('Data', resposnse);
                });

        }


    }, []);

    const submitHandler = (event) => {
        event.preventDefault()
        EditUserRequest()
    }


    function EditUserRequest() {

        const userData = {
            email: email,
            firstName: fname,
            lastName: lname,           
            gender: gender, 
            dateOfBirth: dobdate, 
            userType: 'E',
            statusId: status,
            userId:id,
            roleId: role,
            designation, designation
        };
   

        var response = APICall(userData, 'Users/UpdateUser', 'POST')
            .then(resposnse => {
                console.log('Data', resposnse);
                if (resposnse.StatusCode == 200) {
                    localStorage.setItem('cmsg', JSON.stringify('Staff updated successfully'));
                    navigate('/staff');
                    
                } else {
                    setMessage(resposnse.Message);
                    setError(resposnse.StatusCode);
                    //localStorage.setItem('cmsg', JSON.stringify('Lead created successfully'));
                    //navigate('/leads');
                }
                console.log('Data', resposnse);
            });

    }

    return (
      <>
            <Layout>

                <div class="content-wrapper">
                    <div class="page-header">
                        <h3 class="page-title">
                            <span class="page-title-icon bg-gradient-primary text-white me-2">
                                <i class="mdi  mdi-book-open"></i>
                            </span>  Staff
                        </h3>
                        <a class="nav-link1" href="/staff"><button class="btn btn-primary btn-lg ">Back</button></a>
                    </div>



                    <div class="row">
                        <div class="card">
                            <div class="card-body">
                                <h4 class="card-title">Edit Staff</h4>
                                {error > 0 ?
                                    <div className="text-danger">{message ? <p>{message}</p> : null}</div>
                                    : <div className="error-blue">{message ? <p>{message}</p> : null}</div>
                                }
                                {error != 404 ? <>
                                    <p class="card-description"> </p>
                                    <form class="forms-sample" onSubmit={submitHandler}>
                                        <div class="form-group row">
                                            <label for="exampleInputUsername2" class="col-sm-3 col-form-label">First Name</label>
                                            <div class="col-sm-9">
                                                <input type="text" class="form-control" id="exampleInputFname" placeholder="First Name" defaultValue={fname} onChange={(e) => setFName(e.target.value)} required />
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label for="exampleInputUsername2" class="col-sm-3 col-form-label">Last Name</label>
                                            <div class="col-sm-9">
                                                <input type="text" class="form-control" id="exampleInputLname" placeholder="Last Name" defaultValue={lname} onChange={(e) => setLName(e.target.value)} required />
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label for="exampleInputEmail2" class="col-sm-3 col-form-label">Email</label>
                                            <div class="col-sm-9">
                                                <input type="email" class="form-control" id="exampleInputEmail2" placeholder="Email" defaultValue={email} onChange={(e) => setEmail(e.target.value)} required />
                                            </div>
                                        </div>

                                        <div class="form-group row">
                                            <label for="exampleInputMobile" class="col-sm-3 col-form-label">Gender</label>
                                            <div class="col-sm-9"> <select class="form-control" id="exampleSelectGender" value={gender} onChange={(e) => setGender(e.target.value)} required>
                                                <option value="">Select</option>
                                                <option value="M">Male</option>
                                                <option value="F">Female</option>
                                            </select> </div>
                                        </div>

                                        <div class="form-group row">
                                            <label for="exampleInputMobile" class="col-sm-3 col-form-label">DOB</label>
                                            <div class="col-sm-9">
                                                <DatePicker showIcon
                                                    className="form-control"
                                                    dateFormat="dd/MM/yyyy"

                                                    
                                                    showYearDropdown
                                                    showMonthDropdown
                                                    yearDropdownItemNumber={100}
                                                    scrollableYearDropdown

                                                    selected={dobdate}
                                                    onChange={(date) => setDobDate(date)}
                                                    required
                                                />
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label for="exampleInputEmail2" class="col-sm-3 col-form-label">Designation</label>
                                            <div class="col-sm-9">
                                                <input type="text" class="form-control" id="exampleInputDesignation" placeholder="Designation" defaultValue={designation} onChange={(e) => setDesignation(e.target.value)} required />
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label for="exampleInputMobile" class="col-sm-3 col-form-label">Status</label>
                                            <div class="col-sm-9"> <select class="form-control" id="exampleSelectGender" value={status} onChange={(e) => setStatus(e.target.value)} required>
                                                <option value="">Select</option>
                                                <option value="1">Active</option>
                                                <option value="2">Inactive</option>
                                                <option value="3">Loacked</option>
                                                <option value="4">Deleted</option>
                                            </select> </div>
                                        </div>
                                        <div class="form-group row">
                                            <label for="exampleInputMobile" class="col-sm-3 col-form-label">User Role</label>
                                            <div class="col-sm-9"> <select class="form-control" id="exampleSelectGender" value={role} onChange={(e) => setRole(e.target.value)} required>
                                                <option value="">Select</option>
                                                <option value="1">Admin</option>
                                                <option value="2">Staff</option>
                                            </select> </div>
                                        </div>


                                        <div class="form-check form-check-flat form-check-primary">
                                            <label class="form-check-label" />

                                        </div>
                                        <button type="submit" class="btn btn-gradient-primary me-2">Submit</button>

                                    </form></>
                                    : <>{message} </>
                                }
                            </div>
                        </div>
                    </div>

                </div>

            </Layout>

        </>
    )
}