import React from 'react'

const Pagination = ({ nPages, currentPage, setCurrentPage, onPageChange }) => {

    const pageNumbers = [...Array(nPages + 1).keys()].slice(1)

    const nextPage = () => {
        if (currentPage !== nPages) {
            var pageNumber = currentPage + 1;
            setCurrentPage(pageNumber)
            onPageChange(pageNumber);
        }

    }

    const prevPage = () => {
        if (currentPage !== 1) {
            var pageNumber = currentPage - 1;
            setCurrentPage(pageNumber)
            onPageChange(pageNumber);

        }
    }
    const changePage = (pageNumber) => {
        setCurrentPage(pageNumber)
        onPageChange(pageNumber);
    }

  
    return (
        <nav>
            <ul className='pagination justify-content-center'>
                <li className="page-item">
                    <a className="page-link"
                        onClick={() => prevPage()}
                        href='javascript:void(0)'>

                        Previous
                    </a>
                </li>
                {pageNumbers.map(pgNumber => (
                    <li key={pgNumber}
                        className={`page-item ${currentPage == pgNumber ? 'active' : ''} `} >

                        <a onClick={() => changePage(pgNumber)}
                            className='page-link'
                            href='javascript:void(0)'>

                            {pgNumber}
                        </a>
                    </li>
                ))}
                <li className="page-item">
                    <a className="page-link"
                        onClick={() => nextPage()}
                        href='javascript:void(0)'>

                        Next
                    </a>
                </li>
            </ul>
        </nav>
    )
}

export default Pagination