
import logo from '../assets/logo.png'
import DotsPng from '../assets/vector/dots.svg'
export function DayItinerary({ Itinerary }) {


    function renderList(){
        const listItems = [];
        console.log('ITj=', Itinerary)
        for (let j = 0; j < Itinerary.length; j++) {

            const dayImg = Itinerary[j].packageDayImages;
            const singleImage = dayImg.split('\n');
            let listImage = []
            {
                singleImage != null &&
                    singleImage?.length > 0 ? singleImage.map((dImg) => {
                        if (dImg.trim() != null && dImg.trim() != '' && dImg.length > 0) {
                            listImage.push(<>
                                <img src={dImg} alt="" style={{ width: "200px", height: "95px" }} />
                            </>)
                        }
                    }) : <></>
            }
            
            listItems.push(<div className="itinerary-day-description">
                <h3>
                    <div className="day-number">{Itinerary[j].dayNo}</div>
                    <span>{Itinerary[j].title}</span>
                </h3>
                <p>
                    <div dangerouslySetInnerHTML={{ __html: Itinerary[j].details }} />
                    
                </p>
                <div className="image-wrapper">
                    {listImage }
                </div>
            </div>)
        }
        return listItems;
    }

    return (


      
       <>
        <div className="page itinerary-page" >
            <header className="page-header">
                <div className="logo-wrapper">
                    <img src={logo} alt="" />
                </div>
            </header>

                <section className="page-body">
                    {renderList()}                
            </section>

            <footer className="page-footer">
                <a target="_blank" href="tel:9871190075">
                    Phone: 9871190075
                </a>
                <a target="_blank" href="mailto:query@vacationum.com">
                    Email: query@vacationum.com
                </a>
                <a target="_blank" href="https://www.vacationum.com/">
                    Web: www.vacationum.com
                </a>
            </footer>

            <div className="top-right-dots">
                <img src={DotsPng} />
            </div>
        </div>

      </>
    );
}
