import { useState, useRef,useEffect } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { APICall } from '../utils/utils.js'
import Layout from '../layout/layout'
import "../custom.css"
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import SunEditor, { buttonList } from "suneditor-react";
import 'suneditor/dist/css/suneditor.min.css';

export default function EditQuoteOne() {

    const navigate = useNavigate()
    const [packageId, setPackageId] = useState(0)
    const [packagename, setPackageName] = useState('')
    const [leadpassenger, setLeadPassenger] = useState('')
    const [travelSdate, setTravelSdate] = useState('')
    const [travelEdate, setTravelEdate] = useState('')
    const [departureCity, setDepartureCity] = useState('') 
    const [currency, setCurrency] = useState('')  
    const [holidayPackageId, setHolidayPackageId] = useState(0)

    const [packageInclusion, setPackageInclusion] = useState('')
    const [packageExclusion, setPackageExclusion] = useState('') 
     
   
    const [startDate, setStartDate] = useState("");
    const [message, setMessage] = useState("");
    const [error, setError] = useState("");

    const [packages, setPackages] = useState([]);
    const [filtered, setfiltered] = useState([]);
    const [search, setsearch] = useState("");
    const [booknum, setBookNum] = useState('')

    let { id } = useParams();
   
    const [tempDay, setTempDay] = useState("");

   

    useEffect(() => {

        //const bnum = localStorage.getItem('booknum');
        //if (bnum) {
        //    setBookNum(bnum);
        //}

        if (!id || id === null) {
            id = 0;
            setMessage('Invalid quote Id!');
            setError(400);
        } else {



            var response = APICall(null, 'BookingMasters/GetBookingMasterViewById?bookingMasterId=' + id, 'GET')
                .then(resposnse => {
                   
                    if (resposnse.StatusCode === 200) {
                        // setfiltered(resposnse.Result);
                        setBookNum(resposnse.Result.bookingNo);
                    } else {
                        // setMessage(resposnse.Message);
                        //setError(resposnse.StatusCode);
                    }
                    console.log('Data', resposnse);
                });




            var response = APICall(null, 'PackageDetails/GetByBookingMasterId?bookingMasterId=' + id, 'GET')
                .then(resposnse => {
                     console.log('Data-1-', resposnse);
                    if (resposnse.StatusCode === 200) {
                        // setfiltered(resposnse.Result);
                        setPackageName(resposnse.Result.packageName);
                        searchRef.current.value = resposnse.Result.packageName
                        setLeadPassenger(resposnse.Result.leadPassengerName);
                        setPackageId(resposnse.Result.packageDetailId);
                        setTravelSdate(new Date(resposnse.Result.travelStartDt));
                        setTravelEdate(new Date(resposnse.Result.travelEndDt));
                        setDepartureCity(resposnse.Result.departureCity);
                        setCurrency(resposnse.Result.currency);
                        setHolidayPackageId(resposnse.Result.holidayPackageId)
                        setPackageInclusion(resposnse.Result.packageInclusion)
                        setPackageExclusion(resposnse.Result.packageExclusion)

                       // const substringToRemove = / Nights/gi;
                       ////const tempDaysforIt = resposnse.Result.packageName.replace(substringToRemove, '');
                       // const tempDaysforIt = resposnse.Result.packageName[0];
                       // localStorage.setItem('day', tempDaysforIt);

                        localStorage.setItem('hpid', resposnse.Result.holidayPackageId);
                        localStorage.setItem('bkid', id);
                    } else {
                        // setMessage(resposnse.Message);
                        //setError(resposnse.StatusCode);
                    }
                    console.log('Data', resposnse);
                });

            //setfiltered(

            //    packages.filter((item) =>
            //        item.packageMainHeading.toLowerCase().includes(search.toLowerCase())
            //    )
            //);
        }

        
       


    }, []);

    const searchRef = useRef();
    const enddateRef = useRef();

    useEffect(() => {

        if (!id || id === null) {
            id = 0;
            setMessage('Invalid quote Id!');
            setError(400);
        } else {


            if (search != null && search?.length > 2) {
                console.log(search)
                const packageData = {

                    keyword: search,
                };
                var response = APICall(packageData, 'HolidayPackages/GetByCondition', 'POST')
                    .then(resposnse => {
                        // console.log('Data', resposnse);
                        if (resposnse.StatusCode === 200) {
                            setfiltered(resposnse.Result);
                        } else {
                            setMessage(resposnse.Message);
                            setError(resposnse.StatusCode);
                        }
                        console.log('Data', resposnse);
                    });
            }


            


            //setfiltered(

            //    packages.filter((item) =>
            //        item.packageMainHeading.toLowerCase().includes(search.toLowerCase())
            //    )
            //);
        }
    }, [search]);

    const handleChange = (content) => {
        setPackageInclusion(content)
        
    }

    const handleChange2 = (content) => {
        setPackageExclusion(content)

    }

    function clearCustomer() {
    
        setPackageName(null);
        searchRef.current.value = '';
        setsearch('');
    }
   
    function loadCustomer(item) {
        setPackageName(item.packageMainHeading);
        setHolidayPackageId(item.holidayPackageId)
        const substringToRemove = / Nights/gi;
        const newString = item.duration.replace(substringToRemove, '');
        setTempDay(newString)
        setPackageInclusion(item.inclusionsContent)
        setPackageExclusion(item.exclusionsContent)
        console.log('Item=', item)
       // setPhone(customer.phone);
       // setGender(customer.gender);
       // setCustomer(customer.userId);
    }

    function setEndDate(date) {
        console.log('Days=', parseInt(tempDay)>0)
        if (tempDay != null && tempDay) {
            let date1 = new Date(date);
            console.log('date=', date1)
            date1 = date1.setDate(date1.getDate() + parseInt(tempDay));
            console.log('Newdate=', new Date(date1))
            ////let format2 = date.getDate() + "/" + date.getMonth() + "/" + date.getFullYear();
            enddateRef.current.value = new Date(date1);
            setTravelEdate(new Date(date1))
        }
    }


    const submitHandler = (event) => {
        event.preventDefault()
         createQuoteRequest()
        //console.log('Ed-',new Date(travelEdate))
        //console.log('Sd-',new Date(travelSdate))
        
        
    }


    function createQuoteRequest() {

        const quoteData = {
            packageName: packagename,
            leadPassengerName: leadpassenger,
            travelStartDt: travelSdate,
            travelEndDt: travelEdate,
            currency: currency, 
            departureCity: departureCity,            
            bookingNo: booknum,            
            bookingMasterId: id,
            note: '',
            socialMedia: '',
            packageDetailId: packageId,
            holidayPackageId: holidayPackageId,
            packageInclusion: packageInclusion,
            packageExclusion: packageExclusion
            
           
           
        };
        //navigate('/dashboard');

        var response = APICall(quoteData, 'PackageDetails/CreateUpdatePackage', 'POST')
            .then(resposnse => {
                console.log('Data', resposnse);
                if (resposnse.StatusCode == 201) {
                    
                    const timeDifference = Math.abs(travelEdate - travelSdate);
                    const daysRemaining = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
                    localStorage.setItem('day', daysRemaining);
                    localStorage.setItem('hpid', holidayPackageId);
                    localStorage.setItem('bkid', id);
                    console.log(travelSdate + '--' + travelEdate +'--'+daysRemaining);
                    let packId = resposnse.Result.packageDetailId;
                    navigate('/edit-quote-step-two/' + packId +'/1');
                    
                } else {
                    setMessage(resposnse.Message);
                    setError(resposnse.StatusCode);
                    //localStorage.setItem('cmsg', JSON.stringify('Lead created successfully'));
                    //navigate('/leads');
                }
                console.log('Data', resposnse);
            });

    }

    return (
      <>
            <Layout>

                <div class="content-wrapper">
                    <div class="page-header">
                        <h3 class="page-title">
                            <span class="page-title-icon bg-gradient-primary text-white me-2">
                                <i class="mdi  mdi-book-open"></i>
                            </span>  Quotes
                        </h3>
                         <a class="nav-link1" href="/quotes"><button class="btn btn-primary btn-lg ">Back</button></a>
                    </div>



                    <div class="row">
                        <div class="card">
                            <div class="card-body">
                                <h4 class="card-title">Quote - Package Information -  {booknum}</h4>
                                {id != null ?
                                   <>
                                        <div className="text-danger">{message ? <p>{message}</p> : null}</div>
                                        
                                  
                                
                                <p class="card-description"> </p>
                                <form class="forms-sample" onSubmit={submitHandler}>
                                    <div class="form-group row">
                                                <label for="exampleInputMobile" class="col-sm-3 col-form-label">Package</label>
                                                <div className="col-sm-9">
                                                    {packagename ? <button type="button" class="btn btn-gradient-primary me-2" onClick={(e) => {
                                                        clearCustomer();
                                                    }}>Clear</button> :''}<input autocomplete="off" className="form-control"
                                                    type="text"
                                                    id="search"
                                                    placeholder="Search for..."
                                                    onChange={(e) => setsearch(e.target.value)}
                                                    ref={searchRef} style={{ width: 500 }} required
                                                />  
                                            {search.length > 0 && (
                                                <div className="dropdownsearch">
                                                    {filtered.length > 0 ? (
                                                        filtered.map((item, index) => {
                                                            return (
                                                                <div
                                                                    className="card"
                                                                    key={index}
                                                                    onClick={(e) => {
                                                                        searchRef.current.value = item.packageMainHeading ;
                                                                        setsearch(""); loadCustomer(item);
                                                                    }}>
                                                                    <p>{item.packageMainHeading} </p>
                                                                </div>
                                                            );
                                                        })
                                                    ) : (
                                                        <p>no match</p>
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    
                                    <div class="form-group row">
                                        <label for="exampleInputEmail2" class="col-sm-3 col-form-label">Package Name</label>
                                        <div class="col-sm-9">
                                            {packagename}
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label for="exampleInputMobile" class="col-sm-3 col-form-label">Lead Passenger Name </label>
                                        <div class="col-sm-9">
                                                    <input type="text" class="form-control" id="exampleInputLPname" placeholder="Lead Passenger Name" defaultValue={leadpassenger}  onChange={(e) => setLeadPassenger(e.target.value)} required />
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label for="exampleInputMobile" class="col-sm-3 col-form-label">Travel Start Date</label>
                                                <div class="col-sm-9">
                                                    <DatePicker showIcon 
                                                        className="form-control"
                                                        dateFormat="dd/MM/yyyy"
                                                        selected={travelSdate}
                                                        onChange={(date) => { setTravelSdate(date); setEndDate(date) }}
                                                        required 
                                                    />
                                                </div>

                                                    
                                    </div>
                                    <div class="form-group row">
                                        <label for="exampleInputMobile" class="col-sm-3 col-form-label">Travel End Date</label>
                                                <div class="col-sm-9"> <DatePicker showIcon
                                                    className="form-control"
                                                    dateFormat="dd/MM/yyyy"
                                                    selected={travelEdate}
                                                    onChange={(date) => setTravelEdate(date)}
                                                    required ref={enddateRef }
                                                /> </div>
                                    </div>
                                    <div class="form-group row">
                                        <label for="exampleInputMobile" class="col-sm-3 col-form-label">Departure City</label>
                                                <div class="col-sm-9"> <input type="text" class="form-control" id="exampleInputDcity" placeholder="Departure City" defaultValue={departureCity} onChange={(e) => setDepartureCity(e.target.value)} required /> </div>
                                    </div>
                                   
                                    <div class="form-group row">
                                        <label for="exampleInputMobile" class="col-sm-3 col-form-label">Currency</label>
                                                <div class="col-sm-9"> {currency} <br /><select class="form-control" id="exampleSelectCurrency" value={currency} onChange={(e) => setCurrency(e.target.value)} required>
                                            <option value="">Select</option>
                                            <option value="INR">Rupee</option>
                                            <option value="USD">US Dollar</option>
                                            <option value="BP">British Pound</option>
                                        </select> </div>
                                            </div>

                                        <div class="form-group row">
                                            <label class="col-sm-3 col-form-label">Package Inclusion</label>
                                                <div class="col-sm-9">
                                            
                                                    <SunEditor setOptions={{
                                                        height: 200,
                                                        showPathLabel: false,
                                                        charCounter: true,
                                                        maxCharCount: 3000,
                                                        maxWidth: '700px',
                                                        width: 'auto',
                                                        height: 'auto',
                                                        minHeight: '100px',
                                                        maxHeight: '270px',
                                                        buttonList: [
                                                            ['undo', 'redo', 'font', 'fontSize', 'formatBlock'],
                                                            ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript', 'removeFormat'],
                                                            '/', //Line break
                                                            ['fontColor', 'hiliteColor', 'outdent', 'indent', 'align', 'horizontalRule', 'list', 'table'],
                                                            ['link', 'image', 'fullScreen', 'showBlocks', 'codeView', 'preview', 'print', 'save']
                                                        ] // Or Array of button list, eg. [['font', 'align'], ['image']]
                                                        // plugins: [font] set plugins, all plugins are set by default
                                                        // Other option
                                                    }} onChange={handleChange} setContents={packageInclusion} /> 
                                                {/*<textarea class="form-control" id="examplePackageInclusion" rows="4" onChange={(e) => setPackageInclusion(e.target.value)} >{packageInclusion}</textarea>*/}

                                                </div>
                                        </div>

                                        <div class="form-group row">
                                            <label class="col-sm-3 col-form-label">Package Exclusion</label>
                                                <div class="col-sm-9">
                                                    <SunEditor setOptions={{
                                                        height: 200,
                                                        showPathLabel: false,
                                                        charCounter: true,
                                                        maxCharCount: 3000,
                                                        maxWidth: '700px',
                                                        width: 'auto',
                                                        height: 'auto',
                                                        minHeight: '100px',
                                                        maxHeight: '270px',
                                                        buttonList: [
                                                            ['undo', 'redo', 'font', 'fontSize', 'formatBlock'],
                                                            ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript', 'removeFormat'],
                                                            '/', //Line break
                                                            ['fontColor', 'hiliteColor', 'outdent', 'indent', 'align', 'horizontalRule', 'list', 'table'],
                                                            ['link', 'image', 'fullScreen', 'showBlocks', 'codeView', 'preview', 'print', 'save']
                                                        ] // Or Array of button list, eg. [['font', 'align'], ['image']]
                                                        // plugins: [font] set plugins, all plugins are set by default
                                                        // Other option
                                                    }} onChange={handleChange2} setContents={packageExclusion} /> 

                                                {/*<textarea class="form-control" id="examplepackageExclusion" rows="4" onChange={(e) => setPackageExclusion(e.target.value)} >{packageExclusion}</textarea>*/}

                                               </div>
                                        </div>

                                    <div class="form-check form-check-flat form-check-primary">
                                        <label class="form-check-label" />

                                            </div>
                                   
                                    <button type="submit" class="btn btn-gradient-primary me-2">Next</button>

                                        </form>
                                    </>
                                : 
                                    <div class="form-check form-check-flat form-check-primary text-danger">
                                       
                                    {message ? <p>{message}</p> : null}
                                </div>
                                }
                            </div>
                        </div>
                    </div>

                </div>

            </Layout>

        </>
    )
}