import { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { APICall } from '../utils/utils.js'
import Layout from '../layout/layout'
import { useParams } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export default function EditLead() {

    const navigate = useNavigate()
    const [email, setEmail] = useState('')
    const [fname, setFName] = useState('')
    const [lname, setLName] = useState('')
    const [phone, setPhone] = useState('')
    const [gender, setGender] = useState('')
    const [destinationCity, setDestinationCity] = useState('')
    const [departureCity, setDepartureCity] = useState('') 
    const [departureDate, setDepartureDate] = useState(null) 
    const [duration, setDuration] = useState('') 
    const [adultNumber, setAdultNumber] = useState('') 
    const [childNumber, setChildNumber] = useState('') 
    const [budget, setBudget] = useState('') 
    const [source, setSource] = useState('')
    const [assign, setAssign] = useState('')
    const [snote, setSNote] = useState('')
    
    const [countryId, setCountryId] = useState('') 
    const [country, setCountry] = useState('') 
    const [staff, setStaff] = useState('') 
    //const [customerType, setCustomerType] = useState('') 
    const [leadStatusId, setStatus] = useState('') 
   // const [statusId, setStatusId] = useState('')    

    
    const [message, setMessage] = useState("");
    const [error, setError] = useState("");

    const [lead, setLead] = useState(null);
    let { id } = useParams();
    const [leaderr, setLeaderr] = useState(null);
    let roleId = sessionStorage.getItem('roleId');
    let userId = sessionStorage.getItem('userId');

    useEffect(() => {

        if (!id || id === null) {
            id = 0;
        }
        

        var response = APICall(null, 'Leads/GetLeadById?leadId=' + id, 'GET')
            .then(resposnse => {
                console.log('Data11', resposnse);
                if (resposnse.StatusCode === 200) {
                    setLead(resposnse.Result);
                    setFName(resposnse.Result.firstName);
                    setLName(resposnse.Result.lastName);
                    setEmail(resposnse.Result.email);
                    setPhone(resposnse.Result.phone);
                    setGender(resposnse.Result.gender);
                    setDestinationCity(resposnse.Result.destinationCity);
                    setDepartureCity(resposnse.Result.departureCity);
                    setDepartureDate(new Date(resposnse.Result.departureDate));
                    setDuration(resposnse.Result.duration); 
                    setAdultNumber(resposnse.Result.adultNumber);
                    setChildNumber(resposnse.Result.childNumber);

                    setBudget(resposnse.Result.budget);
                    setSource(resposnse.Result.platformId);
                    setAssign(resposnse.Result.assignUserId);
                    setSNote(resposnse.Result.specialNote);
                    setCountryId(resposnse.Result.countryId);
                    setStatus(resposnse.Result.leadStatusId);
                    //console.log('countryId-', countryId);
                } else {
                   
                    setMessage(resposnse.Message);
                    setLeaderr(resposnse.Message);
                }
                //console.log('Data', lead);
            });
       
        var response = APICall(null, 'Miscellaneous/GetCountries', 'GET')
            .then(resposnse => {
                // console.log('Data', resposnse);
                if (resposnse.StatusCode === 200) {
                    setCountry(resposnse.Result);
                } else {
                    setMessage(resposnse.Message);
                    setError(resposnse.StatusCode);
                }
                console.log('Data', resposnse);
            });

        const userData = {
            userType: 'E',
            keyword:'',
        };
        var response = APICall(userData, 'Users/GetUsersByCondition', 'POST')
            .then(resposnse => {
                // console.log('Data', resposnse);
                if (resposnse.StatusCode === 200) {
                    setStaff(resposnse.Result);
                    
                } else {
                    setMessage(resposnse.Message);
                    setError(resposnse.StatusCode);
                }
                console.log('Data', resposnse);
            });

        

    }, []);

    const handleChange = (event) => {
       // const name = event.target.name;
       // const value = event.target.value;
        setPhone(event.target.value);
    }

    const submitHandler = (event) => {
        event.preventDefault()
        createLeadRequest()
    }

   



    function createLeadRequest() {

        
        

        const leadData = {
            leadId:id,
            email: email,
            firstName: fname,
            lastName: lname,
            phone: phone,
            gender: gender, 
            destinationCity: destinationCity, 
            departureCity: departureCity, 
            departureDate: departureDate, 
            duration: duration,
            adultNumber: adultNumber,
            childNumber: childNumber, 
            budget: budget,
            platformId: source, 
            assignUserId: (roleId != 1) ? userId : assign, 
            departureDate: departureDate,
            countryId: countryId,
            customerType: 'C',
            leadStatusId: leadStatusId,
            statusId: 1,
            specialNote: snote
        };
        //navigate('/dashboard');

        var response = APICall(leadData, 'Leads/UpdateLead', 'POST')
            .then(resposnse => {
                console.log('Data', resposnse);
                if (resposnse.StatusCode == 200) {
                    localStorage.setItem('cmsg', JSON.stringify('Lead updated successfully'));
                    navigate('/leads');
                    
                } else {
                    setMessage(resposnse.Message);
                    setError(resposnse.StatusCode);
                    //localStorage.setItem('cmsg', JSON.stringify('Lead created successfully'));
                    //navigate('/leads');
                }
                console.log('Data', resposnse);
            });

    }

    return (
      <>
            <Layout>

                <div class="content-wrapper">
                    <div class="page-header">
                        <h3 class="page-title">
                            <span class="page-title-icon bg-gradient-primary text-white me-2">
                                <i class="mdi  mdi-book-open"></i>
                            </span>  Leads
                        </h3>
                        <a class="nav-link1" href="/leads"><button class="btn btn-primary btn-lg ">Back</button></a>
                    </div>



                    <div class="row">
                        <div class="card">
                            <div class="card-body">
                                <h4 class="card-title">Edit Lead</h4>
                                {error > 0 ?
                                    <div className="text-danger">{message ? <p>{message}</p> : null}</div>
                                    : <div className="error-blue">{message ? <p>{message}</p> : null}</div>
                                }  
                                <p class="card-description"> </p>
                                {lead != null && lead?.leadId > 0 ?
                               
                                <form class="forms-sample" onSubmit={submitHandler}>
                                    <div class="form-group row">
                                        <label for="exampleInputUsername2" class="col-sm-3 col-form-label">First Name</label>
                                            <div class="col-sm-9">
                                                <input type="text" class="form-control" id="exampleInputFname" placeholder="First Name" defaultValue={lead.firstName} onChange={(e) => setFName(e.target.value)} required />
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label for="exampleInputUsername2" class="col-sm-3 col-form-label">Last Name</label>
                                        <div class="col-sm-9">
                                                <input type="text" class="form-control" id="exampleInputLname" placeholder="Last Name" defaultValue={lead.lastName} onChange={(e) => setLName(e.target.value)} required />
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label for="exampleInputEmail2" class="col-sm-3 col-form-label">Email</label>
                                        <div class="col-sm-9">
                                                <input type="email" class="form-control" id="exampleInputEmail2" placeholder="Email" defaultValue={lead.email} onChange={(e) => setEmail(e.target.value)} required />
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label for="exampleInputMobile" class="col-sm-3 col-form-label">Mobile</label>
                                        <div class="col-sm-9">
                                                <input type="text" class="form-control" id="exampleInputMobile" placeholder="Mobile number" defaultValue={lead.phone} onChange={handleChange} required />
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label for="exampleInputMobile" class="col-sm-3 col-form-label">Gender</label>
                                            <div class="col-sm-9"> <select class="form-control" id="exampleSelectGender" defaultValue={lead.gender} onChange={(e) => setGender(e.target.value)} required>
                                            <option value="">Select</option>
                                            <option value="M">Male</option>
                                            <option value="F">Female</option>
                                        </select> </div>
                                    </div>
                                    <div class="form-group row">
                                        <label for="exampleInputMobile" class="col-sm-3 col-form-label">Destination City</label>
                                            <div class="col-sm-9"> <input type="text" class="form-control" id="exampleInputDestination" defaultValue={lead.destinationCity} placeholder="Destination" onChange={(e) => setDestinationCity(e.target.value)} required /> </div>
                                    </div>
                                    <div class="form-group row">
                                        <label for="exampleInputMobile" class="col-sm-3 col-form-label">Departure City</label>
                                            <div class="col-sm-9"> <input type="text" class="form-control" id="exampleInputdepartureCity" defaultValue={lead.departureCity} placeholder="Departure City" onChange={(e) => setDepartureCity(e.target.value)} required /> </div>
                                    </div>
                                    <div class="form-group row">
                                            <label for="exampleInputMobile" className="col-sm-3 col-form-label">Departure Date</label>
                                            <div className="col-sm-9">
                                                <div className="input-group date" >
                                                    <DatePicker showIcon 
                                                        className="form-control"
                                                        dateFormat="dd/MM/yyyy"
                                                        
                                                        selected={departureDate }
                                                        onChange={(date) => setDepartureDate(date)}
                                                        required
                                                    />
                                                   
                                                </div>
                                                 </div>
                                    </div>
                                    <div class="form-group row">
                                        <label for="exampleInputMobile" class="col-sm-3 col-form-label">Duration</label>
                                            <div class="col-sm-9"> <input type="number" class="form-control" id="exampleInputduration" defaultValue={lead.duration} placeholder="Duration" onChange={(e) => setDuration(e.target.value)} required /> </div>
                                    </div>
                                    <div class="form-group row">
                                        <label for="exampleInputMobile" class="col-sm-3 col-form-label">Adult Number</label>
                                            <div class="col-sm-9"> <input type="number" class="form-control" id="exampleInputadultNumber" defaultValue={lead.adultNumber} placeholder="Adult Number" onChange={(e) => setAdultNumber(e.target.value)} required /> </div>
                                    </div>
                                    <div class="form-group row">
                                        <label for="exampleInputMobile" class="col-sm-3 col-form-label">Child Number</label>
                                            <div class="col-sm-9"> <input type="number" class="form-control" id="exampleInputchildNumber" defaultValue={lead.childNumber} placeholder="Child Number" onChange={(e) => setChildNumber(e.target.value)} required /> </div>
                                    </div>
                                    <div class="form-group row">
                                        <label for="exampleInputMobile" class="col-sm-3 col-form-label">Budget</label>
                                            <div class="col-sm-9"> <input type="number" class="form-control" id="exampleInputbudget" defaultValue={lead.budget} placeholder="Budget" onChange={(e) => setBudget(e.target.value)} required /> </div>
                                    </div>

                                    <div class="form-group row">
                                        <label for="exampleInputMobile" class="col-sm-3 col-form-label">Source</label>
                                            <div class="col-sm-9"> <select class="form-control" id="exampleSelectSource" defaultValue={lead.platformId} onChange={(e) => setSource(e.target.value)} required>
                                            <option value="">Select</option>
                                            <option value="1">Facebook</option>
                                            <option value="2">Instagram</option>
                                            <option value="3">Google</option>
                                        </select> </div>
                                    </div>
                                        {roleId == 1 ?
                                    <div class="form-group row">
                                            <label for="exampleInputMobile" class="col-sm-3 col-form-label">Assign To</label>
                                            
                                                <div class="col-sm-9"> {lead.assingedUser} <br /> <select class="form-control" id="exampleSelectAssign" defaultValue={lead.assignUserId} onChange={(e) => setAssign(e.target.value)} required>
                                                    <option value="">Select</option>

                                                    {staff != null &&
                                                        staff?.length > 0 ? staff.map((staffInfo) => (

                                                            <option selected={lead.assignUserId == staffInfo.userId ? 'selected' : ''} value={`${staffInfo.userId}`}  >{staffInfo.firstName} {staffInfo.lastName}</option>
                                                        )) : <></>
                                                    }

                                                </select> </div>
                                               
                                           
                                            </div> : <>
                                                </>}
                                    <div class="form-group row">
                                        <label for="exampleInputMobile" class="col-sm-3 col-form-label">Country</label>
                                            <div class="col-sm-9">{lead.country} <br /> <select class="form-control" id="exampleSelectCountryId" defaultValue={lead.countryId} onChange={(e) => setCountryId(e.target.value)} required>
                                            <option value="">Select</option>
                                            {country != null &&
                                                country?.length > 0 ? country.map((countryInfo) => (
                                                    <option selected={lead.countryId == countryInfo.countryId ? 'selected' : ''} value={`${countryInfo.countryId}`}>{countryInfo.name}</option>
                                                )):<></>
                                             }                                           
                                        </select> </div>
                                    </div>

                                    <div class="form-group row">
                                        <label class="col-sm-3 col-form-label">Special Note</label>
                                        <div class="col-sm-9">
                                                <textarea class="form-control" id="exampleTextarea1" rows="4" onChange={(e) => setSNote(e.target.value)} >{lead.specialNote}</textarea>
                                        </div>
                                        </div>
                                        <div class="form-group row">
                                            <label for="exampleInputMobile" class="col-sm-3 col-form-label">Status</label>
                                            <div class="col-sm-9"> <select class="form-control" id="exampleSelectStatus" defaultValue={lead.leadStatusId} onChange={(e) => setStatus(e.target.value)} required>
                                                <option value="">Select</option>
                                                <option value="1">NewLead</option>
                                                <option value="2">Assigned</option>
                                                <option value="3">Contacted</option>
                                                <option value="4">NotYetContacted</option>
                                                <option value="5">UnderFollowup</option>
                                                <option value="6">ChangesRequired</option>
                                                <option value="7">YetToConfirm</option>
                                                <option value="8">Confirmed</option>
                                                <option value="9">Concelled</option>
                                                <option value="10">Converted</option>
                                            </select> </div>
                                        </div>
                                           
                                    <div class="form-check form-check-flat form-check-primary">
                                            <label class="form-check-label" /><input type="hidden" name="leadId" defaultValue={lead.LeadId} />
                                    </div>
                                    <button type="submit" class="btn btn-gradient-primary me-2">Submit</button>

                                </form>

                                    : <>
                                        <div className="form-check form-check-flat form-check-primary">
                                            <label className="form-check-label text-danger" >No record founbd! {message}</label>
                                        </div>
                                      </>
                                } 

                            </div>
                        </div>
                    </div>

                </div>

            </Layout>

        </>
    )
}