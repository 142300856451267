import { useState, useRef,useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { APICall } from '../utils/utils.js'
import Layout from '../layout/layout'
import "../custom.css"

export default function CreateQuote() {

    const navigate = useNavigate()
    const [email, setEmail] = useState('')
    const [fname, setFName] = useState('')
    const [lname, setLName] = useState('')
    const [phone, setPhone] = useState('')
    const [gender, setGender] = useState('')
    const [destinationCity, setDestinationCity] = useState('')
    const [custType, setCustType] = useState('')
   
    const [assign, setAssign] = useState('')
    
    
    
    const [customer, setCustomer] = useState('') 
    const [staff, setStaff] = useState('') 
    //const [customerType, setCustomerType] = useState('') 
   // const [leadStatusId, setLeadStatusId] = useState('') 
   // const [statusId, setStatusId] = useState('')    

    
    const [message, setMessage] = useState("");
    const [error, setError] = useState("");

    const [users, setusers] = useState([]);
    const [filtered, setfiltered] = useState([]);
    const [search, setsearch] = useState("");

    let roleId = sessionStorage.getItem('roleId');
    let userId = sessionStorage.getItem('userId');
    //let assignId = userId;

    

    useEffect(() => {

       



        const staffData = {
            userType: 'E',
            keyword: '',
        };
        var response = APICall(staffData, 'Users/GetUsersByCondition', 'POST')
            .then(resposnse => {
                // console.log('Data', resposnse);
                if (resposnse.StatusCode === 200) {
                    setStaff(resposnse.Result);
                } else {
                    setMessage(resposnse.Message);
                    setError(resposnse.StatusCode);
                }
                console.log('Data', resposnse);
            });


    }, []);

    const searchRef = useRef();

    useEffect(() => {
        
        if (search !=null  && search?.length>2) {
            console.log(search)
            const userData = {
                userType: custType,
                keyword: search,
                LastRecordCount:10
            };

            let url = '';

            if (custType == 'L') {
                url = 'Leads/GetLeadforQuoteByCondition'
            } else {
                url = 'Users/GetUsersByConditionForLead'
            }
            var response = APICall(userData, url, 'POST')
                .then(resposnse => {
                    // console.log('Data', resposnse);
                    if (resposnse.StatusCode === 200) {
                        setfiltered(resposnse.Result);
                    } else {
                        setMessage(resposnse.Message);
                        setError(resposnse.StatusCode);
                    }
                    console.log('Data', resposnse);
                });
        }
       // setfiltered(
            
         //   users.filter((item) =>
          //      item.firstName.toLowerCase().includes(search.toLowerCase())
          //  )
       // );
    }, [search]);

   
    function loadCustomer(customer) {
        setEmail(customer.email);
        setPhone(customer.phone);
        setGender(customer.gender);
        setCustomer(customer.userId);
    }


    const submitHandler = (event) => {
        event.preventDefault()
       createQuoteRequest()
        
    }


    function createQuoteRequest() {

       
        
        const quoteData = {
            customerUserId: customer,
            assignedToUserId: (roleId != 1) ? userId : assign,
            leadId: customer,
            customerType: custType,
        
           
        };
        //navigate('/dashboard');

        var response = APICall(quoteData, 'BookingMasters/CreateBookingMaster', 'POST')
            .then(resposnse => {
                console.log('Data', resposnse);
                if (resposnse.StatusCode == 201) {
                    localStorage.setItem('cmsg', JSON.stringify('Quote created successfully'));
                    localStorage.setItem('booknum', resposnse.Result.bookingNo);
                    navigate('/edit-quote-step-one/' + resposnse.Result.bookingMasterId);
                    
                } else {
                    setMessage(resposnse.Message);
                    setError(resposnse.StatusCode);
                    
                }
                console.log('Data', resposnse);
            });

    }

    return (
      <>
            <Layout>

                <div class="content-wrapper">
                    <div class="page-header">
                        <h3 class="page-title">
                            <span class="page-title-icon bg-gradient-primary text-white me-2">
                                <i class="mdi  mdi-book-open"></i>
                            </span>  Quotes
                        </h3>

                    </div>



                    <div class="row">
                        <div class="card">
                            <div class="card-body">
                                <h4 class="card-title">Create Quote</h4>
                                {error > 0 ?
                                    <div className="text-danger">{message ? <p>{message}</p> : null}</div>
                                    : <div className="error-blue">{message ? <p>{message}</p> : null}</div>
                                }  
                                <p class="card-description"> </p>
                                <form className="forms-sample" onSubmit={submitHandler}>
                                    <div className="form-group row">
                                        <label for="exampleInputEmail2" class="col-sm-3 col-form-label">User Type</label>
                                        <div class="col-sm-9">
                                            <div className="form-check form-check-inline">
                                                <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="C" onChange={(e) => setCustType(e.target.value)} required />
                                                <label className="form-check-label" for="inlineRadio1">Customer</label> 
                                        </div>
                                            <div className="form-check form-check-inline">
                                                <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="L" onChange={(e) => setCustType(e.target.value)} required />
                                                <label className="form-check-label" for="inlineRadio2">Lead</label>
                                            </div>

                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label for="exampleInputMobile" class="col-sm-3 col-form-label">Customer</label>
                                        <div class="col-sm-9">  <input autocomplete="off"
                                            type="text"
                                            id="search"
                                            placeholder="Search for..."
                                            onChange={(e) => setsearch(e.target.value)}
                                            ref={searchRef} required
                                        />
                                            {search.length > 0 && (
                                                <div className="dropdownsearch">
                                                    {filtered.length > 0 ? (
                                                        filtered.map((item, index) => {
                                                            return (
                                                                <div
                                                                    className="card"
                                                                    key={index}
                                                                    onClick={(e) => {
                                                                        searchRef.current.value = item.firstName +' '+ item.lastName;
                                                                        setsearch(""); loadCustomer(item);
                                                                    }}>
                                                                    <p>{item.firstName} {item.lastName} ({item.email})</p>
                                                                </div>
                                                            );
                                                        })
                                                    ) : (
                                                        <p>no match</p>
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    
                                    <div class="form-group row">
                                        <label for="exampleInputEmail2" class="col-sm-3 col-form-label">Email</label>
                                        <div class="col-sm-9">{email}
                                            
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label for="exampleInputMobile" class="col-sm-3 col-form-label">Customer/Lead Id</label>
                                        <div class="col-sm-9">
                                            {customer}
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label for="exampleInputMobile" class="col-sm-3 col-form-label">Gender</label>
                                        <div class="col-sm-9"> {gender} </div>
                                    </div>
                                    
                                    {roleId == 1 ?
                                    <div class="form-group row">
                                        <label for="exampleInputMobile" class="col-sm-3 col-form-label">Assign To</label>
                                        <div class="col-sm-9"> <select class="form-control" id="exampleSelectAssign" onChange={(e) => setAssign(e.target.value)} required>
                                            <option value="">Select</option>
                                            {staff != null &&
                                                staff?.length > 0 ? staff.map((staffInfo) => (
                                                    <option value={staffInfo.userId} >{staffInfo.firstName} {staffInfo.lastName}</option>
                                                )) : <></>
                                            } 
                                        </select> </div>
                                    </div>
                                        : <></>
                                    }
                                    <div class="form-check form-check-flat form-check-primary">
                                        <label class="form-check-label" />

                                    </div>
                                    <button type="submit" class="btn btn-gradient-primary me-2">Next</button>

                                </form>
                            </div>
                        </div>
                    </div>

                </div>

            </Layout>

        </>
    )
}