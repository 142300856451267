import { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { APICall } from '../utils/utils.js'
import { useParams } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";

export default function DeleteLead() {

    const navigate = useNavigate()
    let { id } = useParams();

    useEffect(() => {
        console.log(id);
        if (window.confirm('Are you sure, you want to delete lead?')) {

            const leadData = {
                leadId: id
            }
            console.log(id);
            var response = APICall(leadData, 'Leads/DeleteLead?leadId=' + id, 'DELETE')
                .then(resposnse => {
                    console.log('Data', resposnse);
                    if (resposnse.StatusCode === 200) {
                        localStorage.setItem('cmsg', JSON.stringify('Lead Deleted successfully'));
                        navigate('/leads');
                    } else {
                        localStorage.setItem('cmsg', JSON.stringify(resposnse.Message));
                        navigate('/leads');
                    }

                });

        } else { navigate('/leads'); }
    });


}