import { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { APICall } from '../utils/utils.js'
import Layout from '../layout/layout'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export default function CreateLead() {

    const navigate = useNavigate()
    const [email, setEmail] = useState('')
    const [fname, setFName] = useState('')
    const [lname, setLName] = useState('')
    const [phone, setPhone] = useState('')
    const [gender, setGender] = useState('')
    const [destinationCity, setDestinationCity] = useState('')
    const [departureCity, setDepartureCity] = useState('') 
    const [departureDate, setDepartureDate] = useState(null) 
    const [duration, setDuration] = useState('') 
    const [adultNumber, setAdultNumber] = useState('') 
    const [childNumber, setChildNumber] = useState('') 
    const [budget, setBudget] = useState('') 
    const [source, setSource] = useState('')
    const [assign, setAssign] = useState('')
    const [snote, setSNote] = useState('')
    
    const [countryId, setCountryId] = useState('') 
    const [country, setCountry] = useState('') 
    const [staff, setStaff] = useState('') 
    //const [customerType, setCustomerType] = useState('')
   // const [leadStatusId, setLeadStatusId] = useState('')
   // const [statusId, setStatusId] = useState('')

    let roleId = sessionStorage.getItem('roleId');
    let userId = sessionStorage.getItem('userId');

    
    const [message, setMessage] = useState("");
    const [error, setError] = useState("");

    useEffect(() => {

       

        var response = APICall(null, 'Miscellaneous/GetCountries', 'GET')
            .then(resposnse => {
                // console.log('Data', resposnse);
                if (resposnse.StatusCode === 200) {
                    setCountry(resposnse.Result);
                } else {
                    setMessage(resposnse.Message);
                    setError(resposnse.StatusCode);
                }
                console.log('Data', resposnse);
            });

        const userData = {
            userType: 'E',
            keyword: '',
        };
        var response = APICall(userData, 'Users/GetUsersByCondition', 'POST')
            .then(resposnse => {
                // console.log('Data', resposnse);
                if (resposnse.StatusCode === 200) {
                    setStaff(resposnse.Result);
                } else {
                    setMessage(resposnse.Message);
                    setError(resposnse.StatusCode);
                }
                console.log('Data', resposnse);
            });


    }, []);


    const submitHandler = (event) => {
        event.preventDefault()
        createLeadRequest()
    }


    function createLeadRequest() {

        

        const leadData = {
            email: email,
            firstName: fname,
            lastName: lname,
            phone: phone,
            gender: gender, 
            destinationCity: destinationCity, 
            departureCity: departureCity, 
            departureDate: departureDate, 
            duration: duration,
            adultNumber: adultNumber,
            childNumber: childNumber, 
            budget: budget,
            platformId: source, 
            assignUserId: (roleId != 1) ? userId : assign, 
            departureDate: departureDate,
            countryId: countryId,
            customerType: 'C',
            leadStatusId: 1,
            statusId: 1,
            specialNote: snote
        };
        //navigate('/dashboard');

        var response = APICall(leadData, 'Leads/CreateLead', 'POST')
            .then(resposnse => {
                console.log('Data', resposnse);
                if (resposnse.StatusCode == 201) {
                    localStorage.setItem('cmsg', JSON.stringify('Lead created successfully'));
                    navigate('/leads');
                    
                } else {
                    setMessage(resposnse.Message);
                    setError(resposnse.StatusCode);
                    //localStorage.setItem('cmsg', JSON.stringify('Lead created successfully'));
                    //navigate('/leads');
                }
                console.log('Data', resposnse);
            });

    }

    return (
      <>
            <Layout>

                <div class="content-wrapper">
                    <div class="page-header">
                        <h3 class="page-title">
                            <span class="page-title-icon bg-gradient-primary text-white me-2">
                                <i class="mdi  mdi-book-open"></i>
                            </span>  Leads
                        </h3>
                        <a class="nav-link1" href="/leads"><button class="btn btn-primary btn-lg ">Back</button></a>
                    </div>



                    <div class="row">
                        <div class="card">
                            <div class="card-body">
                                <h4 class="card-title">Create Lead</h4>
                                {error > 0 ?
                                    <div className="text-danger">{message ? <p>{message}</p> : null}</div>
                                    : <div className="error-blue">{message ? <p>{message}</p> : null}</div>
                                }  
                                <p class="card-description"> </p>
                                <form class="forms-sample" onSubmit={submitHandler}>
                                    <div class="form-group row">
                                        <label for="exampleInputUsername2" class="col-sm-3 col-form-label">First Name</label>
                                        <div class="col-sm-9">
                                            <input type="text" class="form-control" id="exampleInputFname" placeholder="First Name" onChange={(e) => setFName(e.target.value)} required />
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label for="exampleInputUsername2" class="col-sm-3 col-form-label">Last Name</label>
                                        <div class="col-sm-9">
                                            <input type="text" class="form-control" id="exampleInputLname" placeholder="Last Name" onChange={(e) => setLName(e.target.value)} required />
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label for="exampleInputEmail2" class="col-sm-3 col-form-label">Email</label>
                                        <div class="col-sm-9">
                                            <input type="email" class="form-control" id="exampleInputEmail2" placeholder="Email" onChange={(e) => setEmail(e.target.value)} required />
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label for="exampleInputMobile" class="col-sm-3 col-form-label">Mobile</label>
                                        <div class="col-sm-9">
                                            <input type="text" class="form-control" id="exampleInputMobile" placeholder="Mobile number" onChange={(e) => setPhone(e.target.value)} required />
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label for="exampleInputMobile" class="col-sm-3 col-form-label">Gender</label>
                                        <div class="col-sm-9"> <select class="form-control" id="exampleSelectGender" onChange={(e) => setGender(e.target.value)} required>
                                            <option value="">Select</option>
                                            <option value="M">Male</option>
                                            <option value="F">Female</option>
                                        </select> </div>
                                    </div>
                                    <div class="form-group row">
                                        <label for="exampleInputMobile" class="col-sm-3 col-form-label">Destination City</label>
                                        <div class="col-sm-9"> <input type="text" class="form-control" id="exampleInputDestination" placeholder="Destination" onChange={(e) => setDestinationCity(e.target.value)} required /> </div>
                                    </div>
                                    <div class="form-group row">
                                        <label for="exampleInputMobile" class="col-sm-3 col-form-label">Departure City</label>
                                        <div class="col-sm-9"> <input type="text" class="form-control" id="exampleInputdepartureCity" placeholder="Departure City" onChange={(e) => setDepartureCity(e.target.value)} required /> </div>
                                    </div>
                                    <div class="form-group row">
                                        <label for="exampleInputMobile" class="col-sm-3 col-form-label">Departure Date</label>
                                        <div class="col-sm-9">
                                            <DatePicker showIcon 
                                                className="form-control"
                                                dateFormat="dd/MM/yyyy"

                                                selected={departureDate}
                                                onChange={(date) => setDepartureDate(date)}
                                                required
                                            />
                                             </div>
                                    </div>
                                    <div class="form-group row">
                                        <label for="exampleInputMobile" class="col-sm-3 col-form-label">Duration</label>
                                        <div class="col-sm-9"> <input type="number" class="form-control" id="exampleInputduration" placeholder="Duration" onChange={(e) => setDuration(e.target.value)} required /> </div>
                                    </div>
                                    <div class="form-group row">
                                        <label for="exampleInputMobile" class="col-sm-3 col-form-label">Adult Number</label>
                                        <div class="col-sm-9"> <input type="number" class="form-control" id="exampleInputadultNumber" placeholder="Adult Number" onChange={(e) => setAdultNumber(e.target.value)} required /> </div>
                                    </div>
                                    <div class="form-group row">
                                        <label for="exampleInputMobile" class="col-sm-3 col-form-label">Child Number</label>
                                        <div class="col-sm-9"> <input type="number" class="form-control" id="exampleInputchildNumber" placeholder="Child Number" onChange={(e) => setChildNumber(e.target.value)} required /> </div>
                                    </div>
                                    <div class="form-group row">
                                        <label for="exampleInputMobile" class="col-sm-3 col-form-label">Budget</label>
                                        <div class="col-sm-9"> <input type="number" class="form-control" id="exampleInputbudget" placeholder="Budget" onChange={(e) => setBudget(e.target.value)} required /> </div>
                                    </div>

                                    <div class="form-group row">
                                        <label for="exampleInputMobile" class="col-sm-3 col-form-label">Source</label>
                                        <div class="col-sm-9"> <select class="form-control" id="exampleSelectSource" onChange={(e) => setSource(e.target.value)} required>
                                            <option value="">Select</option>
                                            <option value="1">Facebook</option>
                                            <option value="2">Instagram</option>
                                            <option value="3">Google</option>
                                        </select> </div>
                                    </div>
                                    {roleId == 1 ?
                                    <div class="form-group row">
                                        <label for="exampleInputMobile" class="col-sm-3 col-form-label">Assign To</label>
                                        <div class="col-sm-9"> <select class="form-control" id="exampleSelectAssign" onChange={(e) => setAssign(e.target.value)} required>
                                            <option value="">Select</option>
                                            {staff != null &&
                                                staff?.length > 0 ? staff.map((staffInfo) => (
                                                    <option value={staffInfo.userId} >{staffInfo.firstName} {staffInfo.lastName}</option>
                                                )) : <></>
                                            } 
                                        </select> </div>
                                        </div> : <></>
                                        }
                                    <div class="form-group row">
                                        <label for="exampleInputMobile" class="col-sm-3 col-form-label">Country</label>
                                        <div class="col-sm-9"> <select class="form-control" id="exampleSelectCountryId" onChange={(e) => setCountryId(e.target.value)} required>
                                            <option value="">Select</option>
                                            {country != null &&
                                                country?.length > 0 ? country.map((countryInfo) => (
                                                    <option value={countryInfo.countryId} >{countryInfo.name}</option>
                                                )):<></>
                                             }                                           
                                        </select> </div>
                                    </div>

                                    <div class="form-group row">
                                        <label class="col-sm-3 col-form-label">Special Note</label>
                                        <div class="col-sm-9">
                                            <textarea class="form-control" id="exampleTextarea1" rows="4" onChange={(e) => setSNote(e.target.value)} ></textarea>
                                        </div>
                                    </div>

                                   

                                    <div class="form-check form-check-flat form-check-primary">
                                        <label class="form-check-label" />

                                    </div>
                                    <button type="submit" class="btn btn-gradient-primary me-2">Submit</button>

                                </form>
                            </div>
                        </div>
                    </div>

                </div>

            </Layout>

        </>
    )
}