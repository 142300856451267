import React from "react";



export default function Header() {

    const logout = () => {
       
        sessionStorage.clear();
    }

    var username = sessionStorage.getItem('name');
    

    return (
        <>
            
            <nav className="navbar default-layout-navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
                <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center">
                    <a className="navbar-brand brand-logo" href="/dashboard"><img src="assets/images/logo.png" alt="logo" /></a>
                    <a className="navbar-brand brand-logo-mini" href="/dashboard"><img src="assets/images/logo.png" alt="logo" /></a>
                </div>
                <div className="navbar-menu-wrapper d-flex align-items-stretch">
                    <button className="navbar-toggler navbar-toggler align-self-center" type="button" data-toggle="minimize">
                        <span className="mdi mdi-menu"></span>
                    </button>
                   
                    <ul className="navbar-nav navbar-nav-right">
                        <li className="nav-item nav-profile dropdown">
                            <a className="nav-link dropdown-toggle" id="profileDropdown" href="#" data-bs-toggle="dropdown" aria-expanded="false">
                                <div className="nav-profile-img">
                                    
                                </div>
                                <div className="nav-profile-text">
                                    <p className="mb-1 text-black">{username}</p>
                                </div>
                            </a>
                            <div className="dropdown-menu navbar-dropdown" aria-labelledby="profileDropdown">
                                <a className="dropdown-item" href="/profile" style={{ color: "#000" }}>
                                    <i className="mdi mdi-cached me-2 text-success"></i> Profile </a>
                                <div className="dropdown-divider"></div>

                            </div>
                        </li>

                       
                        
                        <li className="nav-item nav-logout d-none d-lg-block">
                            <a className="nav-link" href="#" onClick={logout}>
                                <i className="mdi mdi-power"></i>
                            </a>
                        </li>
                        
                    </ul>
                    
                </div>
            </nav>
           
        </>
    );
}