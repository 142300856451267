import React from "react";



export default function Footer() {
    return (
        <>
            <footer className="footer">
                <div className="container-fluid d-flex justify-content-between">
                    <span className="text-muted d-block text-center text-sm-start d-sm-inline-block">Copyright @ 2023</span>
                </div>
            </footer>

        </>
    );
}