import { useState, useEffect, useRef } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { APICall } from '../utils/utils.js'
import Layout from '../layout/layout'
//import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export default function Profile() {

    const navigate = useNavigate()
    
    const [fname, setFName] = useState('')
    const [lname, setLName] = useState('')
    const [pass, setPass] = useState('') 
    const [userId, setUserId] = useState('') 
    //const [gender, setGender] = useState('')
    //const [dobdate, setDobDate] = useState('')  
   // const [userType, setUserType] = useState('') 
   // const [status, setStatus] = useState('')
    
        
    const [message, setMessage] = useState("");
    const [error, setError] = useState("");

    const passRef = useRef();

    useEffect(() => {


        var uid = sessionStorage.getItem('userId');
        setUserId(uid)
        if (!uid || uid === null) {
            uid = 0;
            setMessage('Invalid user Id!');
            setError(400);
        } else {


            var response = APICall(null, 'Users/GetUserById/' + uid, 'GET')
                .then(resposnse => {
                    // console.log('Data', resposnse);
                    if (resposnse.StatusCode === 200) {
                        //setEmail(resposnse.Result.email);
                        setFName(resposnse.Result.firstName);
                        setLName(resposnse.Result.lastName);
                       // setGender(resposnse.Result.gender);
                       // setDobDate(new Date(resposnse.Result.dateOfBirth));
                       // setStatus(resposnse.Result.statusId);

                    } else {
                         setMessage(resposnse.Message);
                        setError(resposnse.StatusCode);
                    }
                    console.log('Data', resposnse);
                });

        }


    }, []);

    const submitHandler = (event) => {
        event.preventDefault()
        EditUserRequest()
    }


    function EditUserRequest() {

        const userData = {
            //email: email,
            firstName: fname,
            lastName: lname,           
            password: pass,
          //  dateOfBirth: dobdate, 
          //  userType: 'C',
          //  statusId: status,
            userId: userId,
          //  roleId: 3
        };
   

        var response = APICall(userData, 'Users/UpdateProfile', 'POST')
            .then(resposnse => {
                console.log('Data', resposnse);
                if (resposnse.StatusCode == 200) {
                    //localStorage.setItem('cmsg', JSON.stringify('Profile updated successfully'));
                    setMessage('Profile updated successfully');
                    setPass('')
                    passRef.current.value = '';
                    setError(1);
                } else {
                    setMessage(resposnse.Message);
                    setError(resposnse.StatusCode);
                    //localStorage.setItem('cmsg', JSON.stringify('Lead created successfully'));
                    //navigate('/leads');
                }
                console.log('Data', resposnse);
            });

    }

    return (
      <>
            <Layout>

                <div class="content-wrapper">
                    <div class="page-header">
                        <h3 class="page-title">
                            <span class="page-title-icon bg-gradient-primary text-white me-2">
                                <i class="mdi  mdi-book-open"></i>
                            </span>  Profile
                        </h3>
                        
                    </div>



                    <div class="row">
                        <div class="card">
                            <div class="card-body">
                                <h4 class="card-title">Edit Profile</h4>
                                {error > 0 ?
                                    <div className="text-danger">{message ? <p>{message}</p> : null}</div>
                                    : <div className="error-blue">{message ? <p>{message}</p> : null}</div>
                                }
                                {error != 404 ? <>
                                    <p class="card-description"> </p>
                                    <form class="forms-sample" onSubmit={submitHandler}>
                                        <div class="form-group row">
                                            <label for="exampleInputUsername2" class="col-sm-3 col-form-label">First Name</label>
                                            <div class="col-sm-9">
                                                <input type="text" class="form-control" id="exampleInputFname" placeholder="First Name" defaultValue={fname} onChange={(e) => setFName(e.target.value)} required />
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label for="exampleInputUsername2" class="col-sm-3 col-form-label">Last Name</label>
                                            <div class="col-sm-9">
                                                <input type="text" class="form-control" id="exampleInputLname" placeholder="Last Name" defaultValue={lname} onChange={(e) => setLName(e.target.value)} required />
                                            </div>
                                        </div>
                                        
                                        <div class="form-group row">
                                            <label for="exampleInputUsername2" class="col-sm-3 col-form-label">Password</label>
                                            <div class="col-sm-9">
                                                <input type="password" ref={ passRef} class="form-control" id="exampleInputpass" placeholder="Password" onChange={(e) => setPass(e.target.value)} required />
                                            </div>
                                        </div>
                                        

                                        
                                       
                                        


                                        <div class="form-check form-check-flat form-check-primary">
                                            <label class="form-check-label" />

                                        </div>
                                        <button type="submit" class="btn btn-gradient-primary me-2">Submit</button>

                                    </form></>
                                    : <>{message} </>
                                }
                            </div>
                        </div>
                    </div>

                </div>

            </Layout>

        </>
    )
}