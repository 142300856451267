import { Link } from 'react-router-dom'
import Layout from '../layout/layout'
import { APICall } from '../utils/utils.js'
import { useState, useEffect } from 'react'
import { useParams } from "react-router-dom";
import { format } from 'date-fns';

export default function ViewStaff() {

    
    const [message, setMessage] = useState("");
    //const [error, setError] = useState("");
    const [staff, setStaff] = useState(null);
    let { id } = useParams();

    
    useEffect(() => {
       
        if (!id || id===null) {
            id = 0;
        }
       
        var response = APICall(null, 'Users/GetUserById/'+id, 'GET')
            .then(resposnse => {
               console.log('Data', resposnse);
                if (resposnse.StatusCode === 200) {
                    setStaff(resposnse.Result);
                } else {
                    setMessage(resposnse.Message);
                    //setError(resposnse.StatusCode);
                }
                console.log('Data', staff);
            });


    }, []);

   


    return (
      <>
            <Layout>

                <div className="content-wrapper">
                    <div className="page-header">
                        <h3 className="page-title">
                            <span className="page-title-icon bg-gradient-primary text-white me-2">
                                <i className="mdi  mdi-book-open"></i>
                            </span>  Staff
                        </h3>
                        <a class="nav-link1" href="/staff"><button class="btn btn-primary btn-lg ">Back</button></a>
                    </div>

                    <div className="row">
                        <div className="card">
                            <div className="card-body">
                                <h4 className="card-title">Details</h4>
                                <p className="card-description"> </p>

                                {staff != null && staff?.userId >0?
                                    <form className="forms-sample">
                                        <div className="form-group row">
                                            <label for="exampleInputUsername2" className="col-sm-3 col-form-label">Name</label>
                                            <div className="col-sm-9">
                                                {staff.firstName} {staff.lastName} 
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label for="exampleInputEmail2" className="col-sm-3 col-form-label">Email</label>
                                            <div className="col-sm-9">
                                                {staff.email} 
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label for="exampleInputMobile" className="col-sm-3 col-form-label">DOB</label>
                                            <div className="col-sm-9">
                                                {format(new Date(staff.dateOfBirth), 'dd/MM/yyyy')} 
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label for="exampleInputMobile" className="col-sm-3 col-form-label">Designation</label>
                                            <div className="col-sm-9">
                                                {staff.designation}
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label for="exampleInputMobile" className="col-sm-3 col-form-label">Gender</label>
                                            <div className="col-sm-9"> {staff.gender}  </div>
                                        </div>
                                       
                                        <div className="form-group row">
                                            <label for="exampleInputMobile" className="col-sm-3 col-form-label">Status</label>
                                            <div className="col-sm-9"> {staff.status} </div>
                                        </div>

                                        
                                      

                                        <div className="form-check form-check-flat form-check-primary">
                                            <label className="form-check-label" />
                                            {/* <button onClick={() => navigate(-1)} className="btn btn-primary btn-lg ">Back</button>*/}
                                        </div>

                                    </form>
                                    : <>
                                        <div className="form-check form-check-flat form-check-primary">
                                            <label className="form-check-label text-danger" >No record founbd! {message}</label>

                                            </div>
                                    </>
                                   

                                 } 

                            </div>
                        </div>
                    </div>

                </div>
           

            </Layout>

        </>
    )


}